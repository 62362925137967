.password-item {
  padding-bottom: 0;
  border-bottom: none;
  &_label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 18px;
  }
  &_input {
    width: 196px;
    .ant-input-suffix {
      margin-top: 4px;
      height: 16px;
    }
  }
  &_old {
    & .field-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
  &_new {
    display: flex;
    margin: 14px 0 24px 0;
    &--repeat {
      margin-left: 30px;
    }
  }
}
