.header-mobile {
  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    background: $gray-1;
    position: sticky;
    top: 0;
    z-index: 4;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  }
  &__main {
    width: 100%;
    padding: 0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__title {
    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    &__sub {
      color: $gray-7;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      & .inviteSent {
        color: $pink;
      }
    }
    &__title, &__sub {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }


  }
  &__sub-title {
    font-size: 12px;
    line-height: 16px;
    color: $gray-7;
  }
  &__left {
    z-index: 2;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);

    height: 100%;
    display: flex;
    align-items: center;

    svg {
      color: $gray-8;
      path {
        fill: $gray-8;
      }
    }

    & &__back {
      z-index: 4;
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
    &__add-transfer {
      //margin-left: 16px;
    }
    &__check {
      margin-left: 16px;
    }
  }
  &__right {
    z-index: 2;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    height: 100%;
    display: flex;
    align-items: center;
    svg {
      color: $gray-8;
      path {
        fill: $gray-8;
      }
    }

    & .sync {
      svg {
        color: $gray-8;
        fill: $gray-8;
        width: 24px;
        height: 24px;
      }
      svg, path {
        fill: $gray-8;
      }
    }

  }
  &__menu {
    display: flex;
    align-items: center;
    & .ant-dropdown-trigger {
      height: 32px;
    }
    svg {
      transform: rotate(90deg);
    }
    svg, path {
      fill: $gray-8;
    }
  }
  &__archive {
    svg, path {
      fill: $main;
    }
  }
}
