.search-transparent {
  height: 32px;
  width: 100%;
  padding-left: 4px;
  padding-right: 26px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $gray-5;
  &:focus{
    outline: none;
  }
  &::placeholder {
    color: $gray-6
  }
  &__wrapper {
    position: relative;
  }
  &__icon {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    &.clear {
      cursor: pointer;
    }
    &:hover, &:focus {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
    fill: $gray-6;
    path {
      fill: $gray-6;
    }
  }
}
