.clients-mobile-filter {
  .clients-filter__risk-level {
    width: 100%;
    margin-bottom: 16px;
  }

  .clients-filter__date-picker {
    .date-picker {
      width: 100%;
    }
    &__placeholcer {
      top: 9px;
    }
  }
  .clients-filter__search {
    margin-top: 16px;
    width: 100%;
  }
  .clients-filter__clear {
    margin-left: 0;
    text-align: center;
  }
}