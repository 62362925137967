.wallets-detail {
  position: relative;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid $gray-4;
  background: $gray-1;

  .copy-content {
    position: relative;
    svg {
      position: absolute;
      right: 8px;
      bottom: 12px;
    }

    input {
      padding-right: 35px;
    }
  }

  .field-wrapper {
    width: 100%;
  }
  &-mobile-form {
    background: $gray-light;
  }

  &__dark_mobile {
    color: $gray-6 !important;
    background: $gray-light !important;
  }

  &__dark {
    background: $gray-2;
    cursor: default !important;
    color: $gray-9 !important;
  }

  &__left {
    width: 460px;
    h4 {
      color: $gray-9;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin: 0 0 5px 0;
    }

    p.created-at {
      color: $gray-8;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      margin-bottom: 24px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 416px;

    &-btn {
      display: flex;
      justify-content: end;
      gap: 8px;
      width: 416px;
      margin-bottom: 20px;
      &-save {
        padding: 5px 49px;
      }
      &-cancel {

      }
    }

    &-live-items {
      .ant-switch {
        width: 44px;
        background: $gray-5;
      }
      .ant-switch-checked {
        width: 44px;
        background: $pink;
      }
      border-radius: 4px;
      border: 1px solid $gray-2;
      background: $gray-light;
      padding: 24px 16px;

      &-header {
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        margin-bottom: 16px;

        i {
          color: $error-2;
          text-align: right;
          font-size: 14px;
          display: block;
          font-weight: 400;
          cursor: pointer;
          text-decoration: underline;
        }

        svg {
          color: $blue-3;
        }

        .ant-spin {
          margin-left: 10px;
        }

        span {
          color: $gray-9;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }

      &-check {
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-top: 8px;

        span {
          color: $gray-9;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          padding: 0 6px 0 16px;
        }
        svg {
          path {
            fill: #D7D8E0;
          }
        }
      }
      &-historical {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $gray-3;
        &-key {
          padding: 5px 0 5px 16px;
          color: $gray-8;
          font-size: 12px;
          line-height: 22px;
          text-transform: uppercase;
        }
        &-value {
          padding: 5px 16px 5px 0;
          color: $gray-9;
          line-height: 22px;
          display: flex;
          gap: 3px;
          align-items: center;
          &-default {
            cursor: default;
            text-decoration-line: none !important;
            color: $gray-main !important;
            padding: 5px 16px 5px;
          }
        }
        a {
          color: $main;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          text-decoration-line: underline;
        }
      }
      &-historical:last-child {
        border-bottom: none;
      }
    }

    &-live-items-blocked {
      background: $red-1;
      border: 1px solid $red-6;
      p {
        margin: -10px 0 0 0;
        color: $red-6;
        padding: 0 0 8px 16px;
        width: 80%;
      }
    }

    &-delete-wallet {
      cursor: pointer;
      position: absolute;
      bottom: 28px;
      right: 40px;
      display: flex;
      align-items: center;
      gap: 6px;
      color: $error-2;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-decoration-line: underline;
    }
  }
}
