html {
  --antd-wave-shadow-color: $main;
  --scroll-bar: 0;
}

::selection {
  color: $gray-1;
  background: $main;
}

.ant-notification {
  //z-index: 1032;
}
.ant-drawer {
  //z-index: 2000;
}