.sticky {
  position: sticky;
  top: 0;
  z-index: 3;
}

.transfers {
  &-group {
    display: flex;
    flex-direction: column;
    &__title {
      margin-left: 24px;
      margin-bottom: 16px;
      margin-top: 40px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    &__list {
      display: flex;
      flex-direction: column;
      &:first-child {
        margin-bottom: 40px;
      }
    }
    &:first-child &__title {
      margin-top: 0;
    }
  }
  &-header {
    @include Transition;
    z-index: 4;
    &:not(.stickyTop) {
      background: $gray-2;
      border-bottom: 1px solid $gray-4;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }
    &__content {
      padding: 24px 0;
    }
    &__counter {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray-8;
      display: flex;
      align-items: center;
      &-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: right;
        margin-top: 8px;
      }
    }
    &__new-transfer {
      width: 180px;
      height: 32px;
      font-size: 14px;
      line-height: 22px;
      svg {
        margin-left: 16px;
        transform: rotate(-90deg);
      }
    }
  }
  &-filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .select {
      margin-right: 16px;
    }
    .transfer-type {
      max-width: 186px;
      width: 186px;
    }
    .risk-level {
      max-width: 186px;
      width: 186px;
    }
    .counterparty {
      max-width: 220px;
      width: 220px;
    }
    .asset {
      max-width: 170px;
      width: 170px;
    }
    .blockchain {
      max-width: 170px;
      width: 170px;
      margin-right: 0;
    }
    .date-picker {
      margin-top: 24px;
      max-width: 228px;
      width: 228px;
    }
    .search {
      margin-top: 24px;
      margin-left: 16px;
      width: 346px;
    }
    &__clear {
      margin-top: 24px;
      margin-left: auto;
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      text-decoration-line: underline;
      color: $main;
    }
    &__add-transaction {
      cursor: pointer;
      margin-left: 12px;
      display: flex;
      align-items: center;
      color: $main;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;

      .plus-icon {
        margin-right: 8px;
        width: 22px;
        height: 22px;
      }

      &:hover .plus-icon > path {
        fill: $blue-2;
      }
      &:hover {
        color: $blue-2;
      }

    }
    &__search {
      width: 188px;
      margin-left: auto;
    }
  }
  &-empty {
    margin-top: 200px;
  }
  &-show-more {
    width: 100%;
    &__btn {
      padding: 20px 0;
      width: 100%;
      background: $gray-2;
      border: 1px solid $gray-3;
      border-radius: 2px 2px 16px 16px;
      color: $main;
      &:hover {
        color: $main !important;
        background: $gray-3 !important;
        border: 1px solid $gray-3;
      }

      &:focus {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }
    }
    &__skeleton {
      height: 42px !important;
      width: 100% !important;
      border: 0.5px solid $gray-3;
      border-radius: 2px 2px 16px 16px !important;
      background: $gray-3 !important;
    }
  }
  .bn-pagination {
    margin-top: 24px;
  }
  &-skeleton {
    width: 100%;
    &__title > span {
      width: 140px !important;
      margin-left: 24px;
      margin-bottom: 16px;
      margin-top: 40px;
    }

    &__items > div:first-child > span{
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    &__items > div:last-child > span {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &__item {
      margin-bottom: 8px;
      height: 64px !important;
      width: 100% !important;
    }
  }
  &-content {
    position: relative;
  }
  &-scroll-top {
    cursor: pointer;
    position: sticky;
    left: 100%;
    bottom: 98px;
    height: 48px;
    width: 48px;
    background-color: rgba(185, 187, 194, 0.70);
    border-radius: 50%;
    @include Transition;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      box-shadow: 0 0 0 2px rgba(185, 187, 194, 0.20);
    }
  }
}

@media screen and (max-width: $max-md-media) {

  .transfers-filter .transfer-type  {
    width: 168px;
  }
  .transfers-filter .risk-level {
    width: 168px;
  }
  .transfers-filter .counterparty {
    width: 168px;
  }
  .transfers-filter .blockchain {
    width: 168px;
    margin-top: 0;
  }
  .transfers-filter .asset {
    margin-top: 8px;
    width: 110px;
  }
  .transfers-filter .date-picker {
    width: 226px;
    margin-left: 0;
    margin-top: 8px;
  }
  .transfers-filter .search {
    width: 226px;
    margin-top: 8px;
    margin-left: 16px;
  }
  .transfers-filter__clear {
      margin-top: 12px;
  }
}

@media screen and (max-width: $max-sm-media) {
  .transfers-group__title {
    margin-top: 24px !important;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: fit-content;
      padding: 2px 8px;
      border-radius: 100px;
      background: $gray-7;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: $gray-3;
    }
  }
  .transfers {
    &-group {
      &:first-child &__title {
        margin-top: 40px;
      }
    }
    &-mobile-filter-list {
      overflow: scroll;
      white-space: nowrap;
      display: none;
      align-items: center;
      padding-left: 15px;
      height: 56px;
      border-radius: 0 0 4px 4px;
      scrollbar-width: none;
      padding-top: 24px;
      &::-webkit-scrollbar {
        display: none;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-right: 9px;
        padding: 2px 4px 2px 8px;
        border: 1px solid $gray-4;
        border-radius: 2px;
        background: $gray-2;
        cursor: pointer;
        span {
          margin-right: 5px;
          color: $gray-8;
          font-size: 12px;
          line-height: 24px;
        }
        div {
          @include FlexCenter;
          svg {
            width: 10px;
            height: 10px;
            path {
              color: $gray-8;
            }
          }
        }
      }
      &.active {
        display: flex;
      }
    }
    &-list {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &-mobile-loader {
      max-height: calc(100vh - 240px);
    }
    .bn-pagination {
      margin-left: auto;
      margin-right: auto;
    }
    &-show-more {
      width: calc(100% - 32px);
      margin: 32px 16px 0 16px;
      border-radius: 2px;
      border: 1px solid $gray-5;
      background: $gray-2;
    }
  }
}
