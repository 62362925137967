.choose-transfer {
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 2px;
  border: 1px solid $gray-5;
  color: $gray-6;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  &.error {
    border-color: $red-5;
  }

  &__menu {
    border: 1px solid $gray-5;
    padding: 0;
    max-height: 175px;
    border-radius: 0 0 2px 2px;
    overflow: scroll;
  }
  &__menu &__item {
    border-left: unset;
    border-right: unset;
    &:first-child {
      height: calc(48px + 8px);
      padding-top: 8px;
    }
  }
  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &__not-selected {
    padding: 12px 12px 12px 16px;
    height: 100%;
    &.disabled {
      cursor: not-allowed;
      background: $gray-light;
    }
    &__icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateX(50%);
      rotate: -90deg;
      &.visible {
        rotate: 90deg;
        transform: translateX(-50%);
      }
      path {
        fill: $main;
      }
    }
  }

  &__address  &__item__arrow {
    width: 12px;
    height: 12px;
    margin-left: 8px;
    margin-right: 8px;
  }
  &__address {
    margin-left: auto;
    margin-right: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: end;
  }
  &__item {
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 16px;
    cursor: pointer;
    border: 1px solid $gray-5;
    border-bottom: none;
    &:hover:not(.selected) {
      @include Transition;
      background-color: $gray-light;
    }
    &:first-child {
      border-top: none;
    }
    &.selected {
      border: unset;
    }
    .hint-content {
      align-self: auto;
    }
    .copy-dropdown {
      height: auto;
    }
    .copy-dropdown .bn-dropdown__overlay {
      top: 20px;
    }
    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      @include Transition;
      &.visible {
        transform: rotate(180deg);
      }
    }
    &__data {
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      @include Ellipsis;
      width: 130px;
    }
    &__amount {
      color: $gray-9;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    &__name {
      color: $gray-7;
      font-size: 10px;
      line-height: 12px;
    }
    &__input, &__output {
      @include Ellipsis;
      color: $main;
      font-size: 12px;
      line-height: 14px;
      width: 91px;
      &.info {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    &__info-icon {
      margin-left: 4px;
      width: 14px;
      height: 14px;
    }
    &__address {
      margin-left: auto;
      margin-right: 58px;
      white-space: nowrap;
      color: $gray-8;
      font-size: 10px;
      font-style: normal;
      line-height: 14px;
    }
    &__arrow {
      transform: rotate(180deg);
      margin-left: 10px;
      margin-right: 22px;
      width: 14px;
      height: 14px;
    }
  }
}
