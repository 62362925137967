.my-wallets {
  &-empty {
    width: 100%;
    min-height: 600px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &__text {
      max-width: 545px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-bottom: 24px;
      text-align: center;
    }
    &__add-wallet {
      height: 40px;
      width: 282px;
    }
  }
  &-head {
    margin-bottom: 40px;
    &__text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      color: $gray-8;
      margin-bottom: 24px;
    }
    &__add-wallet {
      height: 40px;
      width: 282px;
    }
  }
  &-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &-list {
    margin-top: 16px;
    &-show-more {
      &__btn {
        margin-top: 16px;
        padding: 20px 0;
        width: 100%;
        background: $gray-2;
        border: 1px solid $gray-3;
        border-radius: 2px 2px 16px 16px;
        color: $main;
        &:hover {
          color: $main !important;
          background: $gray-3 !important;
          border: 1px solid $gray-3;
        }

        &:focus {
          color: $main;
          background: $gray-3;
          border: 1px solid $gray-3;
        }
      }
      &__skeleton {
        height: 42px !important;
        width: 100% !important;
        border: 0.5px solid $gray-3;
        border-radius: 2px 2px 16px 16px !important;
        background: $gray-3 !important;
      }
    }
  }
  &-item {
    width: 100%;
    height: 80px;
    padding: 0 24px 0 40px;
    border: 1px solid #E8E9F0;
    border-radius: 8px;
    background: $gray-1;
    display: flex;
    align-items: center;
    flex-direction: row;
    &:not(:first-child) {
      margin-top: 16px;
    }
    &__blockchain {
      @include Ellipsis;
      width: 226px;
      margin-right: 8px;
      font-size: 16px;
      line-height: 24px;
    }
    &__data {
      @include Ellipsis;
      width: 545px;
      display: flex;
      flex-direction: column;
    }
    &__name {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    &__id {
      color: $gray-7;
      margin-top: 2px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
    &__created-at {
      width: 145px;
      display: flex;
      flex-direction: column;
    }
    &__date {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
    &__time {
      color: $gray-7;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    &__actions {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__edit, &__delete {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
    &__edit {
      margin-right: 16px;
    }
  }
  &-form {
    &__blockchain {
      height: 40px;
      background: $gray-1;
      border: 1px solid $gray-5;
      border-radius: 2px;

      .ant-select-selector {
        height: 38px !important;
      }
      .ant-select-selection-search,
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-top: 4px !important;
      }
      .ant-select-selection-item {
        padding-top: 4px !important;
      }
      .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
        height: 40px;
      }
    }
    &__btn {
      height: 40px;
      margin-top: 24px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
}