.auth {
  position: relative;
  height: 100dvh;
  width: 100%;
  background: $gray-1;
  display: flex;
  justify-content: center;
  &-content {
    justify-content: center;
    width: 588px;
  }
  &-logo {
    &__by-bitok {
      position: absolute;
      display: flex;
      justify-content: center;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      margin-top: 48px;
      svg {
        width: 69px;
        height: 22px;
      }
    }
  }
  &-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0 0 48px;
    padding: 64px 102px 48px 102px;
    background: $gray-3;
    border: 1px solid $gray-4;
    border-radius: 16px;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    &__title {
      margin-bottom: 28px;
      font-weight: 500;
      font-size: 24px;
      color: $gray-9;
      line-height: 32px;
    }
  }
  &-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .field-wrapper {
      width: 100%;
    }
    .save-container,
    .save-content {
      width: 100%;
    }
    &__btn {
      margin-top: 40px;
      width: 100%;
      height: 40px;
      font-size: 14px;
      line-height: 24px;
      border-radius: 2px;
    }
  }
  &-lang {
    margin-top: 24px;
  }
  &-imgs {
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    &__okman-big {
      position: absolute;
      top: -250px;
      left: 250px;
      width: 308.43px;
      height: 300px;
    }
    &__okman-small {
      position: absolute;
      left: -650px;
      bottom: -300px;
      width: 195px;
      height: 197px;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .auth {
    padding: 16px;
    background-color: $gray-2;
    &-content {
      width: 100%;
    }
    &-box {
      padding: unset;
      background: unset;
      border: unset;
      height:100%;
      margin: 0;
      &:has(.save-container) {
        .auth-box__title {
          margin-top: calc((100dvh - 330px) / 2);;
        }
      }
      &__title {
        font-size: 20px;
        line-height: 28px;
        margin: calc((100dvh - 248px) / 2) 0 40px 0;
      }
    }
    &-form {
      position: relative;
      height: calc(100% - 146px);
      min-height: 305px;
      justify-content: unset;
      &:has(.save-content) {
        min-height: 225px;
      }
      .save-container,
      .save-content{
        height: 80px;
        display: flex;
        justify-content: end;
        width:100%;
        position: absolute;
        bottom: 0;
      }
    }
    &-lang {
      margin: 26px 0 24px 0;
    }
  }
}