.alerts-drawer {
  .ant-drawer-body {
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  &__right {
    display: flex;
    flex-direction: column;
    width: calc(100% - 24px);
  }
  &__navigation-left {
    cursor: pointer;
    height: 100%;
    width: 24px;
    background: $gray-3;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    .icon path {
      @include Transition;
    }
    &:hover {
      & .icon path {
        fill: $main;
      }
    }
  }
  &__navigation-bottom {
    width: 100%;
    height: 40px;
    padding: 0 25px 0 5px;
    display: flex;
    align-content: center;
    background: $gray-light;
    margin-bottom: auto;
    @include Transition;
    &:hover {
      background-color: $gray-3;
    }
    .button {
      width: 40px;
      height: 40px;
      border: none;
      border-radius: 0;
      &.next-button {
        margin-left: auto;
      }
      path {
        @include Transition;
        fill: $gray-6;
      }
      &:hover {
        path {
          fill: $main;
        }
      }
      &:disabled {
        background: transparent;
        border: none;
        path {
          fill: $gray-6;
        }
      }
    }
    .prev, .next {
      margin: 12px;
      min-width: 16px;
      min-height: 16px;
    }
    .next {
      transform: rotate(180deg);
    }
  }
}