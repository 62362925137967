.mobile-filter {
  z-index: 4;
  &__row {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
  }

  .search-transparent__wrapper {
    height: 40px;
    margin: 0;
    margin-bottom: 24px;
    width: 100%;
    background-color: $gray-1;
    border-radius: 2px;
    border: 1px solid $gray-4;
    padding: 3px 12px;
    .search-transparent {
      border-bottom: none;
    }
    &::placeholder {
      color: $gray-6
    }
  }
  .date-picker {
    height: 40px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 8px 12px;
    min-height: 40px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-selection-search,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-top: 4px;
  }
}
