.flexible-sankey {
  height: 326px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: fit-content;
  position: relative;
  &__pad {
    position: relative;
    width: 10px;
    height: 100%;
  }
  &__arrow {
    position: absolute;
    top: calc(100% - 28px);
  }

  .disabled{
    pointer-events: none;
  }
  &__empty-column__incoming, &__empty-column__outgoing {
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 258px;
    background-color: $pink;
    transition: all 0.5s ease-out;
    &.length-1 {
      height: 258px;
    }
    &.length-2 {
      height: 260px;
    }
    &.length-3 {
      height: 259px;
    }
    &.length-4 {
      height: 258px;
    }
    &.length-5 {
      height: 258px;
    }
    &.selected {
      width: 8px;
      height: 256px;
      &.length-1 {
        height: 258px;
      }
      &.length-2 {
        height: 252px;
      }
      &.length-3 {
        height: 251px;
      }
      &.length-4 {
        height: 251px;
      }
      &.length-5 {
        height: 252px;
      }
    }
  }
  &__empty-column__incoming {
    right: 8px;
  }
  &__empty-column__outgoing {
    left: 8px;
  }
}

@media screen and (max-width: $max-md-media) {
  .flexible-sankey {
    height: 270px;
    &__empty-column__incoming, &__empty-column__outgoing {
      height: 204px;
      &.length-1 {
        height: 204px;
      }
      &.length-2 {
        height: 206px;
      }
      &.length-3 {
        height: 205px;
      }
      &.length-4 {
        height: 204px;
      }
      &.length-5 {
        height: 204px;
      }
      &.selected {
        width: 8px;
        height: 208px;
        &.length-1 {
          height: 204px;
        }
        &.length-2 {
          height: 204px;
        }
        &.length-3 {
          height: 204px;
        }
        &.length-4 {
          height: 204px;
        }
        &.length-5 {
          height: 204px;
        }
      }
    }
  }
}