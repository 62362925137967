span.fast-filter-icon-block {
  position: absolute;
  background: $gray-light;
  cursor: pointer;
  right: 0;
  display: none;
  height: 34px;
  bottom: 0;
  padding: 7px 16px 0 5px;
  svg path {
    fill: $gray-8;
  }
}

span.fast-filter-icon-block.active {
  display: block;
}

.filter-bar {
  overflow: scroll;
  white-space: nowrap;
  display: none;
  align-items: center;
  padding-left: 15px;
  border-radius: 0 0 4px 4px;
  position: relative;
  scrollbar-width: none;
  padding-top: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
  &.active {
    display: flex;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-right: 9px;
    padding: 2px 4px 2px 8px;
    border: 1px solid $gray-4;
    border-radius: 2px;
    background: $gray-2;
    cursor: pointer;
    span {
      margin-right: 5px;
      color: $gray-8;
      font-size: 12px;
      line-height: 24px;
    }
    div {
      @include FlexCenter;
      svg {
        width: 10px;
        height: 10px;
        path {
          color: $gray-8;
        }
      }
    }
  }
}
