.monitored-wallet {
  &-new-form {
    margin-top: 24px;

    &-title {
      color: $gray-9;
      font-size: 16px;
      line-height: 24px;
    }

    &-select {
      border: 1px solid $gray-5;

      .ant-select-selector {
        min-height: 40px !important;
        align-items: center;
        font-size: 16px;
      }
    }

    @media screen and (max-width: $max-sm-media) {
      &-select {
        .ant-select-selector {
          font-size: 14px;
        }
      }
    }

    &-input-number {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance:textfield; /* Firefox */
      }
    }

    input[type="number"] {
      -moz-appearance:textfield; /* Firefox */
    }

    &-multiple-select {
      .ant-select-selection-placeholder {
        font-size: 16px;
      }

      .ant-select-selection-search {
        margin-inline-start: 0;
      }

      .ant-select-selector {
        align-items: center;
        padding: 0 12px;
        min-height: 40px !important;
      }

      .ant-select-selection-item {
        display: flex;
        align-items: center;

        border-radius: 2px;
        border: 1px solid $gray-4;
        background: $gray-2;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .anticon-close {
        display: flex;
      }
    }

    @media screen and (max-width: $max-sm-media) {
      &-multiple-select {
        .ant-select-selection-placeholder {
          font-size: 14px;
        }
        .ant-select-selector {
          padding: 0 12px !important;
        }
      }
    }
  }

  &__sync-options {
    &-date {
      input {
        font-size: 16px;
      }
    }

    @media screen and (max-width: $max-sm-media) {
      &-minimal {
        margin-bottom: 8px;
      }
      &-date {
        margin-top: 0;

        input {
          font-size: 14px;
        }
      }
    }

    &-switch {
      &-item {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        color: $gray-9;
        font-size: 16px;
        line-height: 24px;

        .ant-switch {
          background: $gray-5;
        }

        .ant-switch-checked {
          background: $pink;
        }
      }


      @media screen and (max-width: $max-sm-media) {

        &-item {
          margin-top: 24px;
        }
      }

      &-check {
        margin-top: 24px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 6px;

        &__disabled {
          color: $gray-6;
        }

        .bn-switch {
          padding-right: 10px;
        }

        .ant-switch {
          background: $gray-5;
        }

        .ant-switch-checked {
          background: $pink;
        }

        svg {
          path {
            fill: #D7D8E0;
          }
        }
      }

      @media screen and (max-width: $max-sm-media) {
        &-check {
          padding-bottom: 0;
        }
      }
    }
  }
}
