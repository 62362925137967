.welcome-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 24px 24px 16px;
  &__wrapper {
    width: 636px;
    .ant-modal-close {
      display: none;
    }
  }
  &__header {
    margin-left: 34px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    &__icon {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: -34px;
    }
  }
  &__data {
    margin-left: 34px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  &__title {
    font-style: italic;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray-7;
  }

  &__start {
    width: 100%;
    height: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}