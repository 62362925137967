.select {
  border: 1px solid $gray-4;
  border-radius: 2px;
  &.noBorder {
    border: none;
    border-radius: 0;
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
    }
  }
}
.error-input {
  border-color: $red-6;
}