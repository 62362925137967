.transfers-mobile-filter {
  .transfers-filter__clear {
    text-align: center;
  }
  .transfer-type, .risk-level, .counterparty, , .date-picker, .search {
    margin-bottom: 16px;
    width: 100%;
  }
  .blockchain {
    width: 100%;
  }
  .asset {
    min-width: 116px;
    margin-left: 16px;
  }
}