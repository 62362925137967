.bn-switch {
  &__label {
    margin-right: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray-9;
  }
  .ant-switch {
    border-radius: 16px;
  }
}