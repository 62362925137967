.default-input {
  font-size: 16px;
  height: 40px;

  .ant-input-password-icon {
    height: 16px;
    width: 16px;
    display: flex;
  }
}

span.error-input {
  box-shadow: none !important;
  border-color: $red-5; // for risk settings
}

span.ant-input-affix-wrapper-disabled {
  border-color: #d9d9d9;
}

.field-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 16px;
  }
  &-label {
    margin-bottom: 8px;
    display: flex;
  }
  &-info {
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }
  label {
    font-size: 14px;
    line-height: 18px;
    color: $gray-9;
  }
  .change-password-icon {
    padding: 0;
    position: absolute;
    right: -30px;
    top: 34px;
    width: 24px;
    height: 24px;
    border: none !important;
    border-radius: 50%;
    svg {
      width: 24px;
      height: 24px;
    }
    path {
      fill: $gray-9;
    }
    &:disabled {
      path {
        fill: $gray-6;
      }
    }
  }
}
