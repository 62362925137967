@keyframes smoothBg {
  0% {
    background-position: calc(6px / sin(45deg)) 0;
  }
}

.transfer {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 64px;
  background-color: $gray-1;
  border-bottom: 1px solid $gray-4;
  border-left: 1px solid $gray-4;
  border-right: 1px solid $gray-4;
  overflow: hidden;
  cursor: pointer;
  @include Transition;
  & > div {
    @include Transition;
  }
  &.isContinuation {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  &.hasContinuation {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    &:last-child {
      border-bottom: none;
    }
  }
  &:first-child {
    border-top: 1px solid $gray-4;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &.incoming {
    .transfer-counterparty {
      background: linear-gradient(270deg, #F8F8F8 35.87%, #FFEEF6 102.91%);
      &:before {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: -23px;
        left: -23px;
        background-color: $gray-1;
        @include Transition;
      }
      &:after {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: 40px;
        left: -23px;
        background-color: $gray-1;
        @include Transition;
      }
    }
  }
  &.outgoing {
    .transfer-crypto {
      background: linear-gradient(90deg, #F3F4F9 43.56%, #E0E7FE 102.91%);
      &:before {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: -23px;
        right: -23px;
        background-color: $gray-1;
        @include Transition;
      }
      &:after {
        content: "";
        width: 45.25px;
        height: 45.25px;
        transform: rotate(45deg);
        position: absolute;
        top: 40px;
        right: -23px;
        background-color: $gray-1;
        @include Transition;
      }
    }
  }
  &:hover {
    background: $gray-light;
    .transfer-counterparty, .transfer-crypto {
      &:before, &:after {
        background-color: $gray-light;
      }
    }
  }
  &__cel {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__col {
    display: flex;
    flex-direction: column;
  }
  &__icon {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &-operation {
    width: 261px;
    display: flex;
    align-items: center;
    .transfer__icon {
      margin-right: 19px;
    }
    &__subject {
      font-size: 14px;
      line-height: 18px;
      &.error {
        color: $red-6
      }
    }
    &__occurred-at {
      font-size: 12px;
      line-height: 16px;
      color: $gray-7;
    }
    &__risk-level {
      height: 100%;
      width: 16px;
      margin-right: 8px;
      border-right: 1px solid $gray-4;
      &.checking {
        background: repeating-linear-gradient(
            45deg,
            $gray-1,
            $gray-1 3px,
            $gray-4 3px,
            $gray-4 6px
        );
        background-size: calc(6px / sin(45deg)) 100%;
        animation: smoothBg 1s linear infinite reverse;
      }
      &.not_checked {
        border-right: 1px solid $red-6;
      }
      &.not_supported {
        background: $gray-5;
      }
      &.low {
        background: repeating-linear-gradient(
            45deg,
            $gray-1,
            $gray-1 3px,
            $red-3 3px,
            $red-3 6px
        );
      }
      &.medium {
        background: $red-3;
      }
      &.high {
        background: $red-6;
      }
      &.severe {
        background: $red-7;
      }
    }
  }
  &-crypto {
    width: 237px;
    padding: 8px;
    display: flex;
    align-items: center;
    position: relative;

    .hint-content {
      align-self: auto;
    }
    .transfer__icon {
      margin-right: 8px;
      margin-top: 2px;
    }
    .crypto-icon {
      width: 24px;
      height: 24px;
    }
    &__client-ext-id {
      position: absolute;
      top: 5px;
      font-size: 10px;
      line-height: 12px;
      color: $gray-7;
    }
    &__any-asset, &__amount {
      margin-top: 14px;
      font-size: 14px;
      line-height: 22px;
      &.noClientId {
        margin-top: 0;
      }
    }
    &__amount {
      font-weight: 500;
    }
    &__details {
      min-height: 12px;
      @include Ellipsis;
      max-width: 146px;
      font-size: 12px;
      line-height: 14px;
      color: $gray-7;
      display: flex;
      align-items: center;
      .asset-amount {
        margin-left: 4px;
      }
    }
  }
  &-counterparty {
    position: relative;
    max-width: 237px;
    display: flex;
    align-items: center;
    padding: 8px;
    padding-left: 61px;
    overflow: hidden;

    .hint-content {
      align-self: auto;
    }
    .transfer__icon {
      margin-right: 8px;
    }
    &__category {
      position: absolute;
      top: 5px;
      font-size: 10px;
      line-height: 12px;
      color: $gray-7;
    }
    &__name {
      width: 146px;
      margin-top: 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      @include Ellipsis;
      &.no-category {
        margin-top: 0;
      }
    }
    &__address {
      @include Ellipsis;
      max-width: 136px;
      font-size: 10px;
      line-height: 12px;
      color: $gray-7;
    }
  }
  &-additional {
    margin-left: auto;
    padding: 20px 16px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__tag {
      background: $gray-4;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      span {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: $gray-8;
        width: 24px;
      }
    }
    &__notes {
      margin-left: 8px;
      circle {
        fill: $gray-4;
      }
      path {
        fill: $gray-8;
      }
    }
  }
}


@media screen and (max-width: $max-md-media) {
  .transfer {
    &.incoming {
      .transfer-counterparty {
        width: 223px;
        padding-left: 45px;
      }
      .transfer-crypto {
        width: 194px;

      }
    }
    &.outgoing {
      .transfer-crypto {
        width: 223px;

      }
      .transfer-counterparty {
        width: 194px;
        padding-left: 20px;
      }
    }
    &-operation {
      width: 213px;
    }
    &-additional {
      padding: 22px 18px 24px 22px;
      width: 90px;
    }
    &-crypto {
      &__client-ext-id {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 180px;
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .transfer {
    position: relative;
    padding-left: 8px;
    height: 122px;
    flex-direction: column;
    border-left: none;
    border-right: none;
    border-radius: 0;
    align-items: normal;
    border-top: 1px solid $gray-4;
    &:first-child {
      border-radius: 0;
    }
    &:last-child {
      border-bottom-left-radius: 0;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &-head {
      width: 100%;
      height: 44px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-bottom: 1px solid $gray-4;
    }
    &-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: hidden;
    }

    &-crypto, &-counterparty {
      max-width: unset;
    }

    &.incoming &-counterparty {
      width: calc(50% + 19px);
      padding-left: 51px;
      &:before {
        width: 53.74px;
        height: 53.74px;
        top: -27px;
        left: -28px;
      }
      &:after {
        width: 53.74px;
        height: 53.74px;
        top: 49px;
        left: -27px;
      }
    }
    &.incoming &-crypto {
      width: calc(50% - 19px);
    }
    &.outgoing &-crypto {
      width: calc(50% + 19px);
      &:before {
        width: 53.74px;
        height: 53.74px;
        top: -27px;
        right: -28px;
      }
      &:after {
        width: 53.74px;
        height: 53.74px;
        top: 49px;
        right: -27px;
      }
    }
    &.outgoing &-counterparty {
      width: calc(50% - 19px);
    }

    &-crypto {
      padding-left: 10px;
      &__client-ext-id {
        top: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 136px;
      }
    }
    &-counterparty {
      padding-right: 16px;
      &__category {
        top: 8px;
      }
    }
    &:hover {
      background: $gray-1;
      .transfer-counterparty, .transfer-crypto {
        &:before, &:after {
          background-color: $gray-1;
        }
      }
    }
    &-operation__risk-level {
      width: 8px;
      top: 0;
      left: 0;
      position: absolute;
    }
    &-additional {
      &__tag {
        background-color: $pink;
        span {
          color: $gray-1;
        }
      }
      &__notes {
        circle {
          fill: $pink;
        }
        path {
          fill: $gray-1;
        }
      }
      &__menu {
        svg, path {
          fill: $main;
        }
      }
    }
  }
}
@media screen and (max-width: $sub-sm-media) {
  .transfer {
    &-counterparty {
      &__name {
        width: 100px;
      }
      &__address {
        max-width: 100px;
      }
    }
  }
}
@media screen and (max-width: $md-xs-media) {
  .transfer {
    &-counterparty {
      &__name {
        width: 80px;
      }
      &__address {
        max-width: 80px;
      }
    }
  }
}
