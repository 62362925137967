.settings-safety {
  .auth-item {
    padding-top: 0;
    &_content {
      margin-left: 24px;
      span {
        margin-right: 16px;
        font-size: 16px;
        line-height: 24px;
      }
      .auth-switcher {
        background: $main;
        opacity: 0.4;
        border-radius: 16px;
      }
      p {
        margin-top: 8px;
        width: 219px;
        font-size: 16px;
        line-height: 24px;
        color: $gray-8;
      }
    }
  }
  .email-item {
    padding-top: 0;
    margin-bottom: 20px;
  }
  .item_text {
    font-size: 16px;
    line-height: 24px;
    color: $gray-8;

    &.danger {
      color: $error-2
    }
  }
  .item-link {
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: $main;

    &.email {
      margin-left: 16px;
    }
  }
}


@media screen and (max-width: $max-md-media) {
  .settings-safety {
    width: 720px;
  }
}

@media screen and (max-width: $max-sm-media) {
  .settings-safety {
    width: 100%;
    padding: 0;
    background: unset;
    border: unset;
    .settings__item {
      padding-top: 40px;
      padding-bottom: 10px;
    }
    .settings__item .item-title {
      margin-bottom: 16px;
    }
    .password-item {
      //padding-bottom: 40px;
    }
  }
}

@media screen and (max-width: $max-xs-media) {
  .settings-safety {
    .password-item_input {
      width: 100%;
    }
    .password-item_new {
      margin: 0;
      flex-direction: column;
      & > div {
        margin-top: 16px;
      }
    }
    .password-item_new--repeat {
      margin-left: 0;
    }
    .password-item_btn {
      margin-top: 24px;
    }
  }
}