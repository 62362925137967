.plan {
  width: 690px;
  min-height: 454px;
  &.loading {
    height: 454px;
  }
  &-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &-check-pack {
    position: relative;
    border: 1px solid $gray-2;
    max-width: 476px;
    margin-top: 24px;
    padding: 8px 16px 16px 16px;
    background: $gray-light;
    border-radius: 4px;
    &__inner-wrapper {
      display: flex;
      flex-direction: column;
    }
    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    &__expiration-date {
      color: $gray-7;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      &.red {
        color: $red-6;
      }
    }
    &__progress {
      width: 280px;
      margin-top: 8px;
    }
  }
  &-history {
    margin-top: 40px;
    max-width: 476px;
    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $gray-7;
    }
    &__items {
      min-height: 120px;
      margin-top: 8px;
    }
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      border-bottom: 1px solid $gray-4;
      &:nth-child(2n) {
        background: $gray-light;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      div > span {
        &:first-child {
          padding-right: 8px;
          margin-right: 80px;
        }
        &:last-child {
          padding: 0 8px;
        }
      }
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        white-space: nowrap;
        @include Ellipsis;
        &:last-child {
          padding-left: 8px;
        }
      }
    }
    &__see-all {
      float: right;
      &.transparent {
        padding: 0;
        border: none;
        height: 20px;
        span {
          color: $main;
          font-weight: 400;
          font-size: 14px;
          line-height: 144%;
        }
        &:hover {
          color: $main;
          border-color: $gray-1;
        }
      }
      &.widthIconButton {
        svg, path {
          width: 14px;
          height: 14px;
          fill: $main;
        }
      }
      &__wrapper {
        margin-top: 16px;
        padding-right: 16px;
      }
    }

    &__show-more {
      width: 100%;

      &__wrapper {
        margin-top: 16px;
        width: 100%;
      }
      &__skeleton {
        height: 40px !important;
        width: 100% !important;
        border: 0.5px solid $gray-5;
      }
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .plan {
    width: 720px;
  }
}

@media screen and (max-width: $max-sm-media) {
  .plan {
    padding: 8px 16px 0 16px;
    border: none;
    background: none;
    width: 100%;
    &-check-pack {
      padding: 8px 32px 29px 16px;
      max-width: unset;
      margin-top: 0;
      margin-right: 0;
      border-radius: 4px;
      border: 1px solid var(--Gray-light, #FAFAFC);
      background: var(--gray-gray-2, #F3F4F9);
      &__progress {
        width: 100%;
      }
    }
    &-history {
      max-width: unset;
      width: 100%;
      margin-top: 27px;
      &__item {
        div > span {
          &:first-child {
            padding-right: 8px;
            margin-right: 12px;
          }
          &:last-child {
            padding: 0 8px;
          }
        }
        &:last-child {
          border-bottom: unset;
        }
      }
      &__see-all {
        float: unset;
        margin: auto;
        &__wrapper {
          color: $main;
          margin-top: 11px;
          text-decoration: underline;
        }
      }
    }

  }
}
