.aml-risk-settings-detail {
  min-height: 734px;
  padding: 40px;
  background: $gray-1;
  border: 1px solid $gray-4;
  border-radius: 8px;
  &__loading {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  &__common {
    position: relative;
    width: 100%;
    &__data {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
    }
    &__row {
      display: flex;
      align-items: center;
      flex-direction: row;
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
    &__item {
      min-width: 212px;
      &__label {
        margin-right: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
      &__value {
        font-size: 14px;
        line-height: 22px;
        color: $gray-7;
      }
      &.active > &__value  {
        color: $green-3;
      }
    }
    &__head {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  &__title {
    @include Ellipsis;
    max-width: 550px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
  &__edit {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 16px;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    top: 0;
    &__dublicate {
      height: 40px;
      width: 184px;
    }
    &__cancel {
      width: 81px;
      margin-right: 8px;
      border-radius: 2px;
    }
    &__save {
      min-width: 184px;
      border-radius: 2px;
      font-size: 16px;
      line-height: 24px;
    }
    & > button {
      font-size: 16px;
      line-height: 24px;
    }

  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
  }
  &__categories {
    width: 188px;
    display: flex;
    flex-direction: column;
    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
    &__search {
      margin-top: 16px;
      .search-transparent {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }
    &__list {
      padding-left: 25px;
      margin-left: -25px;
      margin-top: 24px;
      max-height: 418px;
      border-right: 1px solid $gray-4;
      border-color: $gray-4;
      overflow-y: scroll;
      @include Transition;
      &.no-border {
        border: none;
      }
      &__empty {
        width: 100%;
        font-size: 12px;
        line-height: 16px;
        color: $gray-8;
        text-align: center;
      }
      &__wrapper {
        position: relative;
      }
      &__top-border {
        z-index: 1;
        position: absolute;
        top: 24px;
        height: 1px;
        width: 188px;
        background-color: $gray-4;
      }
      &__bottom-border {
        z-index: 1;
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 188px;
        background-color: $gray-4;
      }
    }
    &__item {
      position: relative;
      max-width: 172px;
      font-size: 14px;
      line-height: 18px;
      color: $gray-8;
      @include Transition;
      cursor: pointer;
      &:not(:first-child) {
        margin-top: 8px;
      }
      &.active {
        font-weight: 500;
        color: $gray-9;
      }
      &:hover {
        color: $gray-9;
      }
      &.touched {
        color: $pink;
        &:hover {
          color: $pink;
        }
        & .aml-risk-settings-detail__cloud-add {
          left: -16px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  &__custom-address {
    padding-bottom: 60px;
    width: 704px;
    margin-top: 28px;
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $gray-8;
    }
  }
  &__table {
    position: relative;
    padding: 0 100px;
    width: 100%;
    margin-top: 24px;
    tr {
      position: relative;
      .aml-risk-settings-detail__cloud-add {
        left: 0;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }
    th, td {

      padding: 16px 0 16px 16px;
      &:first-child {
        padding-left: 28px;
      }
      &:last-child {
        padding-right: 48px;
      }
    }
    th {
      text-align: left;
      background: $gray-2;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $gray-8;
    }
    &__box {
      min-height: 24px;
      padding: 4px 8px;
      border: 1px solid $gray-4;
      border-radius: 2px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $gray-8;
      @include Transition;
    }
    .risk-level-box {
      width: 112px;
      &.high {
        background: $red-3;
      }
      &.low {
        background: $gray-3;
      }
      &.severe {
        color: $gray-1;
        background: $red-6;
      }
      &.medium {
        background: $red-1;
      }
    }
    .rule-type {
      width: 168px;
    }
    .rule-sub-type {
      width: 132px;
    }
    .min-value {
      width: 80px;
    }
    .min-share {
      width: 72px;
      padding: 4px 8px;
    }
    &__delete {
      cursor: pointer;
      right: 0;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      fill: $main;
      path {
        fill: $main;
      }
    }
    &__field {
      padding: 0;
      margin: 0;
      height: 24px;
      border: 1px solid $gray-4;
      border-radius: 2px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $gray-8;
      &.empty {
        color: $gray-6 !important;
        input {
          color: $gray-6 !important;
        }
      }
      @include Transition;

      &.high {
        &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background: $red-3;
        }
      }
      &.low {
        &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background: $gray-3;
        }
      }
      &.severe {
        &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background: $red-6;
        }
        &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
          color: $gray-1;
        }
        .ant-select-arrow svg {
          fill: $gray-1;
        }
      }
      &.medium {
        &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background: $red-1;
        }
      }
      &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 21px;
      }

      &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      &.ant-select-selection-search,
      &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-top: 2px;
        line-height: 16px;
        color: $gray-9;
      }
      &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        color: $gray-6;
      }
      .ant-select-arrow svg {
        fill: $gray-9;
      }
      & .ant-input {
        padding-left: 8px;
        font-size: 12px;
      }
      &.ant-input-number, &.ant-input-number .ant-input-number-input {
        height: 24px;
        color: $gray-9;
      }
    }
    &__add-btn {
      @include TransparentBtn;
      position: absolute;
      height: 32px;
      width: 188px;
      font-size: 14px;
      line-height: 24px;
      right: 0;
      top: calc(100% + 40px);
    }
  }
  &-mobile {
    text-align: center;
    width: 256px;
    margin: 100px auto 0 auto;
    font-size: 16px;
    line-height: 24px;
    color: $gray-8;
  }
  &__cloud-add {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

}

@media screen and (max-width: $max-md-media) {
  .aml-risk-settings-detail {
    padding: 0;
    background: $gray-light;
    border: none;
    border-radius: 0;
    &__title {
      max-width: 350px;
    }
    &__content {
      flex-direction: column;
    }
    &__categories-mobile {
      width: 100%;
      &__selector {
        height: 40px;
        width: 336px;
        background: $gray-1;
        border: 1px solid $gray-5;
        border-radius: 2px;

        .ant-select-selector {
          height: 38px !important;
        }
        .ant-select-selection-search,
        .ant-select-single .ant-select-selector .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
          padding-top: 4px !important;
        }
        .ant-select-selection-item {
          padding-top: 4px !important;
        }
        .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input,
        .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
          height: 40px;
        }
      }
    }
    &__custom-address {
      margin-top: 32px;
      width: 100%;
    }
    &__table {
      margin-top: 0;
      &__box {
        background-color: $gray-1;
      }
      td.proximity-type-box {
        padding-right: 68px;
      }
    }
  }
}
