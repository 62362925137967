.clients {
  &-header {
    padding-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &-filter {
    display: flex;
    align-items: center;
    .select {
      margin-right: 24px;
    }

    &__risk-level {
      width: 186px;
    }
    &__search {
      margin-left: 24px;
      width: 188px;
    }
    &__clear {
      margin-left: 24px;
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      text-decoration-line: underline;
      color: $main;
    }
  }

  &-content {
    width: 100%;
    margin-top: 43px;
  }
  &-title {
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: transparent;
  }
  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 8px;
    width: 100%;
    margin-top: 24px;
    tr:not(.first-child) {
      cursor: pointer;
      @include Transition;
      &:hover {
        background-color: $gray-light
      }
    }
    th {
      padding: 9px 16px 9px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;
      &:first-child {
        padding-left: 8px;
        padding-right: 8px;
        text-align: center;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }
    td {
      height: 56px;
      max-height: 56px;
      padding-left: 8px;
      border-bottom: 1px solid $gray-4;
      &:first-child {
        padding-left: 0;
        position: relative;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }
    &__main {
      font-size: 14px;
      line-height: 22px;
    }
    &__sub {
      display: flex;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      color: $gray-8;
    }
    &__risk-level {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      .clients-table__main {
        @include Ellipsis;
        max-width: 120px;
      }
      &__cel {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84px;
        height: 24px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 16px;
        &.no_risk, &.none {
          border-radius: 2px;
          border: 1px solid $gray-4;
          background: $accept-light;
        }
        &.low {
          background-color: $gray-1;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23FFA39E' stroke-width='4' stroke-dasharray='10%2c 11' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
      }
    }
    &__client-id {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
      .clients-table__main {
        @include Ellipsis;
        max-width: 136px;
      }
    }
    &__active-alerts {
      width: 172px;
      min-width: 172px;
      max-width: 172px;
    }
    &__last-alert {
      width: 172px;
      min-width: 172px;
      max-width: 172px;
    }
    &__transfer-valume {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
    }
    &__last-transfer {
      width: 172px;
      min-width: 172px;
      max-width: 172px;
    }
    &.noBorderBottom {
      border-bottom: none;
      td {
        border-bottom: none;
      }
    }
    &__empty {
      margin-top: 200px;
    }
  }
  &-show-more {
    margin-top: 8px;
    width: 100%;

    &__btn {
      padding: 20px 0;
      width: 100%;
      background: $gray-2;
      border: 1px solid $gray-4;
      border-radius: 2px 2px 16px 16px;
      color: $main;

      &:hover {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }

      &:focus {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }
    }

    &__skeleton {
      height: 42px !important;
      width: 100% !important;

      border: 0.5px solid $gray-5;
      border-radius: 2px 2px 16px 16px !important;
    }
  }
  &-skeleton {
    width: 100%;
    &__risk-level {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-skeleton-button {
        width: 84px;
        height: 24px;
      }
    }
    .clients-table__main, .clients-table__sub {
      .ant-skeleton-button {
        height: 16px;
        width: 100%;
      }
    }
  }
}
.clients-filter__date-picker {
  position: relative;
  .date-picker {
    width: 244px;
  }
  &.showPlaceholder {
    .ant-picker-range-separator {
      display: none;
    }
  }
  &__placeholcer {
    top: 5px;
    left: 13px;
    position: absolute;
    font-size: 14px;
    line-height: 22px;
    color: $gray-6;
  }
}


@media screen and (max-width: $max-md-media) {
  .clients {
    &-filter {
      .select {
        margin-right: 16px;
      }
      .date-picker {
        width: 214px;
      }
      &__risk-level {
        width: 168px;
      }
      &__search {
        margin-left: 16px;
      }
    }
    &-table {
      &__wrapper {
        padding-bottom: 16px;
        width: 720px;
        overflow-x: scroll;

        .ps__rail-x {
          border-radius: 4px;
          background: $gray-2;
          height: 10px;
        }
      }
    }
  }
  .clients-details-exposure-list__items {
    margin-right: 8px;
  }
}

@media screen and (max-width: $max-sm-media) {
  .clients {
    &-content {
      margin-top: 0;
      margin-bottom: 24px;
    }
    &-show-more {
      margin-top: 32px;
      width: 100%;
      padding: 0 16px;
      &__btn {
        padding: 20px 0;
        border: 1px solid $gray-5;
        border-radius: 2px;
      }
      &__skeleton {
        border-radius: 2px !important;
      }
    }
    &-mobile-loader {
      max-height: calc(100vh - 240px);
    }
    &-mobile-list-item {
      height: 56px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-bottom: 1px solid $gray-4;
      background-color: $gray-1;
      &:first-child {
        border-top: 1px solid $gray-4;
      }
      &__risk-level {
        height: 100%;
        width: 12px;
        min-width: 12px;
        border-left: 1px solid $gray-4;
        &.low {
          background: repeating-linear-gradient(
              45deg,
              $gray-1,
              $gray-1 3px,
              $red-3 3px,
              $red-3 6px
          );
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
      }
      &__content {
        padding-left: 12px;
        padding-right: 16px;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 100%;
        border-left: 1px solid $gray-4;
      }
      &__cel {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__main {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        @include Ellipsis;
      }
      &__sub {
        width: 100%;
        color: $gray-8;
        font-size: 12px;
        line-height: 16px;
        @include Ellipsis;
      }
      &__created-at {
        width: 82px;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
      }
      &__transfers {
        @include Ellipsis;
        text-align: start;
        justify-content: start;
        align-items: flex-start;
      }
      &__alerts {
        padding-left: 8px;
        align-items: flex-end;
        margin-left: auto;
      }
      &__alerts &__main {
        text-align: end;
        font-weight: 500;
      }
    }
    .bn-pagination {
      margin-left: auto;
      margin-right: auto;
    }
    .ps__rail-x {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
