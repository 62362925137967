.new-header {
  width: 100%;
  &__wrapper {
    width: 100%;
    height: 48px;
    background-color: $gray-2;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1000;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &__left {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &__right {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &__checks {
    text-align: center;
    padding: 1px 9px 3px;
    cursor: pointer;
    background: $gray-4;
    border-radius: 40px;
    font-size: 12px;
    line-height: 20px;
    @include Transition;
    &:hover {
      background-color: $pink;
      color: $gray-1;
    }
  }
  &__back {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    &__wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &__icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      @include Transition;
    }
    &__title {
      margin-right: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      @include Transition;
    }
    &:hover &__icon {
      path {
        fill: $gray-8;
      }
    }
    &:hover &__title {
      color: $gray-8;
    }
  }
  &__currency {
    margin-right: 30px;
  }
  &__sync-account {
    margin-right: 16px;
    display: flex;
    justify-content: center;
    &__btn {
      padding: 9px 16px;
      height: 40px;
      color: $gray-9;
      border-radius: 40px;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      @include Transition;
      svg {
        @include Transition;
        margin-right: 8px;
        width: 16px;
        height: 16px;
        path {
          fill: $gray-9;
        }
      }
      &:focus {
        color: $gray-7;
      }
      .sync-loader {
        position: absolute;
        left: 23px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &.error {
        color: $red-6;
        svg path {
          fill: $red-6;
        }
        &:hover {
          color: $gray-1;
          background-color: $red-5;
          svg path {
            fill: $gray-1;
          }
        }
      }
      &.synchronizing {
        padding-left: 40px;
        pointer-events: none;
        color: $gray-7;
        svg path {
          fill: $gray-7;
        }
        &:hover {
          svg path {
            fill: rgba(0, 0, 0, 0.25);
          }
        }
        &:focus {
          color: $gray-7;
          svg path {
            fill: $gray-7;
          }
        }
      }
      &.noAccounts {
        color: $gray-1;
        background-color: $pink;
        &:hover {
          opacity: .8;
        }
      }
    }
    .default-btn.transparent:hover {
      color: $gray-8;
      background-color: $gray-4;
      svg path {
        fill: $gray-8;
      }
    }
  }
  &__drawer {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    &_title {
      z-index: 2;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: $gray-9;
    }
    &__body {
      .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
        border-right: none;
      }
      .ant-drawer-body {
        overflow-x: hidden;
        padding: 0;
      }
      .ant-drawer-body > .scrollbar-container{
        padding: 40px 0;
        display: flex;
        flex-direction: column;
      }
      &.onboardingIsRun {
        .ant-drawer-body{
          pointer-events: none;
        }
      }
    }
  }
  &__additional-menu {
    & .bn-dropdown__overlay {
      background-color:$gray-1;
      top: 35px;
    }
  }
}

.new-header-hamburger {
  position: relative;
  display: block;
  cursor: pointer;
  width: 25px;
  height: 22px;

  &_line {
    position: relative;
    display: block;
    width: 100%;
    height: 2.5px;
    margin-bottom: 8px;
    background: $gray-8;
    transition: all 0.3s ease 0s;
    border-radius: 1px;
  }

  .line--bottom {
    margin-bottom: 0;
  }

  &._isHamburgerActive {
    .new-header-hamburger_line {
      left: 0;
      position: absolute;
    }

    .line--center {
      transform: scale(0) translateY(-50%);
    }

    .line--top {
      top: 50%;
      transform: rotate(-45deg) translateY(-50%);
    }

    .line--bottom {
      bottom: 50%;
      transform: rotate(45deg) translateY(50%);
    }
  }
}


$new-header-was-touch: 1236px; // was a touch burger with new header content
@media screen and (max-width: $new-header-was-touch) {
  .new-header {
    &__wrapper {
      padding: 0 40px;
    }
    &.container {
      margin-left: 0;
    }
    &__drawer {
      position: static;
      margin-right: 48px;
      top: auto;
      left: auto;
      transform: none;
    }
  }
}


@media screen and (max-width: $max-md-media) {
  .new-header {
    &__wrapper {
      &.hideDrawer {
        padding-left: 24px;
      }
    }
  }
}
