.copy-content {
  display: flex;
  align-content: center;
  flex-wrap: wrap;

  .icon {
    cursor: pointer;
    margin-top: 2px;
    margin-left: 4px;;
  }
}