.counterparty-block {
  position: relative;
  margin-top: 40px;
  padding: 24px 24px 40px 24px;
  min-height: 422px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid $gray-4;
  background: $gray-1;
  &__header {
    position: absolute;
    top: 24px;
    left: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &__download-icon {
    display: flex;
    align-items: center;
  }
  &__checked_at {
    position: absolute;
    left: 24px;
    bottom: 24px;
    color: $gray-7;
    font-size: 12px;
    line-height: 16px;
  }
  &__not-checked, &__not-supported {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
      color: $gray-8;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    &__btn {
      margin-top: 16px;
      width: 282px;
      height: 40px;
      color: $gray-1;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  &__not-supported {
    &__title {
      margin-top: 16px;
    }
  }
  &__data {
    margin-top: 60px;
    padding-bottom: 20px;
    padding-right: 8px;
    width: 460px;
    height: fit-content;
  }
  &__identified {
    margin-top: 24px;
    padding: 28px 12px 24px 12px;
    border-radius: 8px;
    background: $gray-2;
    .text-wrap {
      overflow: hidden;
      text-align: end;
      span {
        display: flex;
        flex-direction: column;
      }
    }
    &__title {
      margin-left: 16px;
      margin-bottom: 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
  &__item {
    width: 100%;
    padding: 5px 16px;
    font-size: 12px;
    line-height: 22px;
    color: $gray-8;
    display: flex;
    justify-content: space-between;
    span {
      color: $gray-9;
      font-size: 14px;
      line-height: 22px;
    }
    &:not(:first-child) {
      border-top: 1px solid $gray-3;
    }
  }
  &__empty {
    height: 296px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.error {
      height: 150px;
    }
  }
  &__laoding {
    color: $gray-8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    width: 162px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &__error {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $gray-8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &__btn {
      margin-top: 16px;
      width: 282px;
      height: 40px;
      border-radius: 4px;
    }
  }
  &__chart {
    position: relative;
    margin-top: 60px;
    margin-right: 88px;
    height: 280px;
    width: 280px;
    display: flex;
    align-items: center;
    &__error-close {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .counterparty-block {
    margin-top: 24px;
    &__data {
      margin-right: 48px;
      padding-bottom: 0;
    }
    &__chart {
      height: 260px;
      width: 260px;
      margin-right: 32px;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .counterparty-block {
    min-height: 300px;
    padding: 24px 8px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    background-color: transparent;
    &__header {
      top: 27px;
      left: 24px;
    }
    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    &__download-icon {
      display: none;
    }
    &__data {
      margin-top: 24px;
      margin-right: 0;
      width: 100%;
      padding-right: 0;
    }
    &__checked_at {
      background-color: $gray-2;
      border-radius: 2px;
      padding: 8px 12px;
      bottom: unset;
      left: unset;
      right: 16px;
      top: 20px;
    }
    &__identified {
      margin-top: 12px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 16px 0 16px 0;
      &__title {
        font-size: 14px;
        margin-bottom: 8px;
      }
      .counterparty-block__item:first-child {
        margin-top: 0;
      }
    }
    &__item:first-child {
      margin-top: 16px;
    }
    &__not-checked, &__not-supported {
      height: 300px;
    }
    &__chart {
      margin-top: 24px;
      margin-right: 0;
    }
  }
}
