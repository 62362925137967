$opacity: 0.3;
$translation: 70px;

.modal-route-right {
  opacity: $opacity;
  animation: slide-in-right 0.6s ease-in-out forwards;
}

.modal-route-left {
  opacity: $opacity;
  animation: slide-in-left 0.6s ease-in-out forwards;
}

@keyframes slide-in-right {
  0% {
    opacity: $opacity;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-$translation);
  }
}

@keyframes slide-in-left {
  0% {
    //opacity: $opacity;
    transform: translateX(-$translation);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
