.fast-checks {
  &__header-top {
    padding: 19px 24px 24px 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $gray-1;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

    div.wrapper {
      gap: 0;
      width: 100%;
      padding: 16px 17px 16px 24px;
      display: flex;
      background: #fff;
      position: absolute;
      top: 56px;
      overflow: hidden;
      z-index: 1;

      .ant-input-affix-wrapper {
        width: 97.8%;
      }

      button {
        transform: translateX(30px);
        margin-right: -100%;
        transition: all 0.3s ease-in-out;
      }
    }

    div.fixed {
      position: fixed;
      top: 0;
    }

    div.small {
      button {
        margin-left: 10px;
        transform: translateX(0);
        margin-right: 0;
      }
    }

    .wrapper-shadow {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }

    button {
      margin: 0 24px;
      transition: 0.3s ease-in-out !important;
    }

    .minimal-button {
      width: 40px;
      height: 40px;
      padding: 0 12px;
      margin: 0;
    }

    h4 {
      margin: 0 0 90px 0;
      padding: 0;
      color: $gray-9;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    input {
      outline: none;
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &-check {
    &__wrapper {
      padding: 32px 0;
      background-color: $gray-2;
      border-bottom: 1px solid $gray-4;
      border-top: 1px solid $gray-4;
    }
    display: flex;
    flex-direction: row;

    &__input {
      height: 40px;
      border-radius: 2px;
      border: 1px solid $gray-5;
      &.error {
        border-color: $red-5;
      }
      &__wrapper {
        width: 100%;
        position: relative;
      }
      &__error {
        transition: 0.5s;
        position: absolute;
        color: $red-5;
        font-size: 14px;
        line-height: 22px;
        opacity: 0;
        bottom: 0;
        @include Transition;
        &.visible {
          opacity: 1;
          bottom: -22px;
        }
      }
    }
    &__btn {
      width: 220px;
      height: 40px;
      margin-left: 32px;
    }
  }

  &-header {
    margin-top: 40px;
  }
  &-filter {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    .select:not(:last-child) {
      margin-right: 16px;
    }
    &__check-type {
      width: 196px;
    }
    &__blockchain {
      width: 196px;
    }
    &__date-picker {
      width: 216px;
    }
    &__clear {
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      path {
        @include Transition;
      }
      &:hover {
        path {
          fill: $main;
        }
      }
    }
    &__search {
      margin-left: auto;
      width: 220px;
    }
  }

  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    width: 100%;
    margin-top: 24px;
    &__loading {
      width: auto;
    }
    tr:not(.first-child) {
      @include Transition;
      &:hover {
        background-color: $gray-light
      }
    }
    th {
      height: 40px;
      padding-left: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;
      &:first-child {
        padding-left: 16px;
        padding-right: 8px;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }
    td {
      height: 56px;
      max-height: 56px;
      padding-left: 8px;
      border-bottom: 1px solid $gray-4;
      @include Ellipsis;
      &:first-child {
        padding-left: 12px;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }
    &__item {
      cursor: pointer;
    }
    &__main {
      font-size: 14px;
      line-height: 22px;
      @include Ellipsis;
      &.error {
        color: $red-6;
      }
    }
    &__sub {
      display: flex;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      color: $gray-7;
      @include Ellipsis;
    }

    &__risk-level {
      position: relative;
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      &__loading {
        position: absolute;
        top: 50%;
        left: 43%;
        width: 16px;
        height: 16px;
      }
      &__box {
        width: 84px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        font-size: 12px;
        line-height: 16px;
        &.no_risk, &.none {
          border-radius: 2px;
          border: 1px solid $gray-4;
          background: $accept-light;
        }
        &.low {
          background-color: $gray-1;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23FFA39E' stroke-width='4' stroke-dasharray='10%2c 11' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
        &.error {
          gap: 5px;
          color: $error-2;
          font-size: 14px;
          line-height: 22px;
        }
      }

    }
    &__check-type {
      width: 180px;
      min-width: 180px;
      max-width: 180px;
    }
    &__blockchain {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }
    &__address-or-tx-hash {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
    }
    &__address-or-tx-hash &__main {
      max-width: 156px;
    }
    &__assets {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }
    &__created-at {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
    }

    &__status {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
    }
    &__item &__status {
      display: flex;
      align-items: center;
      flex-direction: row;
      &__icon {
        margin-right: 12px;
      }
    }
    &__status &__main {
      width: 180px;
      @include Ellipsis;
    }
    &__amount {
      width: 130px;
      min-width: 130px;
      max-width: 130px;
    }
    &__wallet {
      width: 170px;
      min-width: 170px;
      max-width: 170px;
    }
    &__invoice-id {
      width: 140px;
      min-width: 140px;
      max-width: 140px;
    }
    &__client-id {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
    }
    &__time-left {
      width: 96px;
      min-width: 96px;
      max-width: 96px;
    }
    &__time-left &__main {
      color: $gray-7;
    }
    &__empty {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-show-more {
    &__btn {
      padding: 20px 0;.fast-checks {
      &__header-top {
        padding: 24px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: $gray-1;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

        div.wrapper {
          gap: 0;
          width: 100%;
          padding: 16px 14px 16px 24px;
          display: flex;
          background: #fff;
          position: absolute;
          top: 72px;
          overflow: hidden;
          z-index: 1;

          .ant-input-affix-wrapper {
            width: 97.8%;
          }

          button {
            transform: translateX(30px);
            margin-right: -100%;
            transition: all 0.3s ease-in-out;
          }
        }

        div.fixed {
          position: fixed;
          top: 0;
        }

        div.small {
          button {
            margin-left: 10px;
            transform: translateX(0);
            margin-right: 0;
          }
        }

        .wrapper-shadow {
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        }

        button {
          margin: 0 24px;
          transition: 0.3s ease-in-out !important;
        }

        .minimal-button {
          width: 40px;
          height: 40px;
          padding: 0 12px;
          margin: 0;
        }

        h4 {
          margin: 0 0 100px 0;
          padding: 0;
          color: $gray-9;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        input {
          outline: none;
        }
      }

      &-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }
      &-check {
        &__wrapper {
          padding: 32px 0;
          background-color: $gray-2;
          border-bottom: 1px solid $gray-4;
          border-top: 1px solid $gray-4;
        }
        display: flex;
        flex-direction: row;

        &__input {
          height: 40px;
          border-radius: 2px;
          border: 1px solid $gray-5;
          &.error {
            border-color: $red-5;
          }
          &__wrapper {
            width: 100%;
            position: relative;
          }
          &__error {
            transition: 0.5s;
            position: absolute;
            color: $red-5;
            font-size: 14px;
            line-height: 22px;
            opacity: 0;
            bottom: 0;
            @include Transition;
            &.visible {
              opacity: 1;
              bottom: -22px;
            }
          }
        }
        &__btn {
          width: 220px;
          height: 40px;
          margin-left: 32px;
        }
      }

      &-header {
        margin-top: 40px;
      }
      &-filter {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        .select:not(:last-child) {
          margin-right: 16px;
        }
        &__check-type {
          width: 196px;
        }
        &__blockchain {
          width: 196px;
        }
        &__date-picker {
          width: 216px;
        }
        &__clear {
          margin-left: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          path {
            @include Transition;
          }
          &:hover {
            path {
              fill: $main;
            }
          }
        }
        &__search {
          margin-left: auto;
          width: 220px;
        }
      }

      &-table {
        border-collapse: separate !important;
        border-spacing: 0;
        overflow: hidden;
        background: $gray-1;
        border: 1px solid $gray-4;
        border-bottom: none;
        border-radius: 8px 8px 0 0;
        width: 100%;
        margin-top: 24px;
        &__loading {
          width: auto;
        }
        tr:not(.first-child) {
          @include Transition;
          &:hover {
            background-color: $gray-light
          }
        }
        th {
          height: 40px;
          padding-left: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          text-align: left;
          background: $gray-2;
          &:first-child {
            padding-left: 16px;
            padding-right: 8px;
          }
          &:not(:first-child) {
            position: relative;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 22px;
              background-color: $gray-4;
            }
          }
        }
        td {
          height: 56px;
          max-height: 56px;
          padding-left: 8px;
          border-bottom: 1px solid $gray-4;
          @include Ellipsis;
          &:first-child {
            padding-left: 12px;
          }
        }
        tr:not(:first-child) {
          border-bottom: 1px solid $gray-4;
        }
        &__item {
          cursor: pointer;
        }
        &__main {
          font-size: 14px;
          line-height: 22px;
          @include Ellipsis;
          &.error {
            color: $red-6;
          }
        }
        &__sub {
          display: flex;
          font-size: 12px;
          line-height: 16px;
          height: 16px;
          color: $gray-7;
          @include Ellipsis;
        }

        &__risk-level {
          position: relative;
          width: 120px;
          min-width: 120px;
          max-width: 120px;
          &__loading {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 16px;
            height: 16px;
          }
          &__box {
            width: 84px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            font-size: 12px;
            line-height: 16px;
            &.no_risk, &.none {
              border-radius: 2px;
              border: 1px solid $gray-4;
              background: $accept-light;
            }
            &.low {
              background-color: $gray-1;
              background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23FFA39E' stroke-width='4' stroke-dasharray='10%2c 11' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
            }
            &.medium {
              background: $red-3;
            }
            &.high {
              color: $gray-1;
              background: $red-6;
            }
            &.severe {
              color: $gray-1;
              background: $red-7;
            }
            &.error {
              gap: 5px;
              color: $error-2;
              font-size: 14px;
              line-height: 22px;
            }
          }

        }
        &__check-type {
          width: 180px;
          min-width: 180px;
          max-width: 180px;
        }
        &__blockchain {
          width: 160px;
          min-width: 160px;
          max-width: 160px;
        }
        &__address-or-tx-hash {
          width: 200px;
          min-width: 200px;
          max-width: 200px;
        }
        &__address-or-tx-hash &__main {
          max-width: 156px;
        }
        &__assets {
          width: 160px;
          min-width: 160px;
          max-width: 160px;
        }
        &__created-at {
          width: 160px;
          min-width: 160px;
          max-width: 160px;
        }

        &__status {
          width: 230px;
          min-width: 230px;
          max-width: 230px;
        }
        &__item &__status {
          display: flex;
          align-items: center;
          flex-direction: row;
          &__icon {
            margin-right: 12px;
          }
        }
        &__status &__main {
          width: 180px;
          @include Ellipsis;
        }
        &__amount {
          width: 130px;
          min-width: 130px;
          max-width: 130px;
        }
        &__wallet {
          width: 170px;
          min-width: 170px;
          max-width: 170px;
        }
        &__invoice-id {
          width: 140px;
          min-width: 140px;
          max-width: 140px;
        }
        &__client-id {
          width: 120px;
          min-width: 120px;
          max-width: 120px;
        }
        &__time-left {
          width: 96px;
          min-width: 96px;
          max-width: 96px;
        }
        &__time-left &__main {
          color: $gray-7;
        }
        &__empty {
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &-show-more {
        &__btn {
          padding: 20px 0;
          width: 100%;
          background: $gray-2;
          border: 1px solid $gray-4;
          border-top: none;
          border-radius: 0 0 16px 16px;

          color: $main;

          &:hover {
            color: $main;
            background: $gray-3;
            border: 1px solid $gray-3;
            border-top: none;
          }

          &:focus {
            color: $main;
            background: $gray-3;
            border: 1px solid $gray-3;
          }
        }
        &__skeleton {
          height: 42px !important;
          width: 100% !important;

          border: 0.5px solid $gray-5;
          border-radius: 2px 2px 16px 16px !important;
        }
      }
      &-form {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          height: 40px;
        }
        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-selection-search,
        .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
          padding-top: 4px;
        }

        &__submit {
          margin-top: 24px;
          height: 40px;
          width: 100%;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

      @media screen and (max-width: $max-md-media) {
        .fast-checks {
          &-filter {
            margin-left: 0;
            &__check-type {
              width: 150px;
            }
            &__blockchain {
              width: 150px;
            }
            &__date-picker {
              width: 216px;
            }
            &__clear {
              margin-left: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &__search {
              margin-left: auto;
              width: 140px;
            }
          }
          &-table {
            width: 996px;
            &__content {
              width: calc(996px + (100% - 720px));
              padding-left: 80px;
            }
            &__wrapper {
              overflow: scroll;
              padding-bottom: 16px;
            }
          }
          .ps__rail-x {
            //border-radius: 4px;
            //background: $gray-2;
            //height: 10px;
            //margin-left: 80px;
            //margin-right: calc(100vw - 720px - 80px);
            display: none !important;
          }
        }
      }

      @media screen and (max-width: $more-md-media) {
        .fast-checks {
          &-table {
            width: 996px;
            &__content {
              padding-left: calc((100vw - 720px) / 2);
              padding-right: calc((100vw - 720px) / 2);
            }
          }
          .ps__rail-x {
            margin-left: calc((100vw - 720px) / 2);
            margin-right: calc((100vw - 720px) / 2);
          }
        }
      }

      @media screen and (max-width: $max-sm-media) {
        @keyframes AnimationName {
          0% {
            background-position: calc(6px / sin(45deg)) 0;
          }
        }

        .mobile-filter {
          .fast-checks-filter {
            &__check-type {
              width: 100%;
            }
            &__blockchain {
              margin-top: 16px;
              width: 100%;
            }
            &__date-picker {
              margin-top: 16px;
              width: 100%;
            }
            &__search {
              margin-top: 16px;
              width: 100%;
            }
            &__clear {
              text-align: center;
              margin-left: auto;
              cursor: pointer;
              font-size: 14px;
              line-height: 22px;
              text-decoration-line: underline;
              color: $main;
            }
          }
        }
        .fast-checks {
          &-content {
            margin-top: 0;
            position: relative;
          }
          .ps__rail-x {
            margin-left: auto;
            margin-right: auto;
          }
          &-list {
            padding-top: 24px;
          }
          &-mobile {
            &-list-item {
              height: 56px;
              display: flex;
              align-items: center;
              flex-direction: row;
              border-top: 1px solid $gray-4;
              background-color: $gray-1;
              &:last-child {
                border-bottom: 1px solid $gray-4;
              }
              &__risk-level {
                height: 100%;
                width: 12px;
                min-width: 12px;
                border-left: 1px solid $gray-4;
                &.low {
                  background: repeating-linear-gradient(
                      45deg,
                      $gray-1,
                      $gray-1 3px,
                      $red-3 3px,
                      $red-3 6px
                  );
                }
                &.medium {
                  background: $red-3;
                }
                &.high {
                  color: $gray-1;
                  background: $red-6;
                }
                &.severe {
                  color: $gray-1;
                  background: $red-7;
                }
                &.checking {
                  background: repeating-linear-gradient(
                      45deg,
                      $gray-1,
                      $gray-1 3px,
                      $gray-3 3px,
                      $gray-3 6px
                  );
                  background-size: calc(6px / sin(45deg)) 100%;
                  animation: AnimationName 1s linear infinite reverse;
                }
                &.error {
                  border-right: 2px solid $red-6;
                }
              }
              &__content {
                margin-right: 14px;
                padding-left: 12px;
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 100%;
                height: 100%;
                border-left: 1px solid $gray-4;
              }
              &__cel {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              &__main {
                width: 100%;
                font-size: 14px;
                line-height: 22px;
                @include Ellipsis;
                &.error {
                  color: $error-2;
                }
              }
              &__sub {
                width: 100%;
                color: $gray-8;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                @include Ellipsis;
              }
              &__check-type {
                @include Ellipsis;
                text-align: start;
                justify-content: start;
                font-size: 12px;
                line-height: 16px;
                align-items: flex-start;
              }
              &__amount-risky-value {
                padding-left: 8px;
                align-items: flex-end;
                margin-left: auto;
              }
              &__amount-risky-value &__main, &__amount-risky-value &__sub {
                text-align: end;
              }
              &__amount-risky-value &__sub {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
              }
              &__created-at {
                margin-top: 3px;
                color: $gray-7;
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
          &-show-more {
            margin-top: 32px;
            width: 100%;
            padding: 0 16px;
            &__btn {
              padding: 20px 0;
              border: 1px solid $gray-5;
              border-radius: 2px;
            }
            &__skeleton {
              border-radius: 2px !important;
            }
          }
          &-mobile-loader {
            max-height: calc(100vh - 240px);
          }
          .bn-pagination {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      width: 100%;
      background: $gray-2;
      border: 1px solid $gray-4;
      border-top: none;
      border-radius: 0 0 16px 16px;

      color: $main;

      &:hover {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
        border-top: none;
      }

      &:focus {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }
    }
    &__skeleton {
      height: 42px !important;
      width: 100% !important;

      border: 0.5px solid $gray-5;
      border-radius: 2px 2px 16px 16px !important;
    }
  }
  &-form {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-selection-search,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-top: 4px;
    }

    &__submit {
      margin-top: 24px;
      height: 40px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .fast-checks {
    &-filter {
      margin-left: 0;
      &__check-type {
        width: 150px;
      }
      &__blockchain {
        width: 150px;
      }
      &__date-picker {
        width: 216px;
      }
      &__clear {
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__search {
        margin-left: auto;
        width: 140px;
      }
    }
    &-table {
      width: 996px;
      &__content {
        width: calc(996px + (100% - 720px));
        padding-left: 80px;
      }
      &__wrapper {
        overflow: scroll;
        padding-bottom: 16px;
      }
    }
    .ps__rail-x {
      //border-radius: 4px;
      //background: $gray-2;
      //height: 10px;
      //margin-left: 80px;
      //margin-right: calc(100vw - 720px - 80px);
      display: none !important;
    }
  }
}

@media screen and (max-width: $more-md-media) {
  .fast-checks {
    &-table {
      width: 996px;
      &__content {
        padding-left: calc((100vw - 720px) / 2);
        padding-right: calc((100vw - 720px) / 2);
      }
    }
    .ps__rail-x {
      margin-left: calc((100vw - 720px) / 2);
      margin-right: calc((100vw - 720px) / 2);
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  @keyframes AnimationName {
    0% {
      background-position: calc(6px / sin(45deg)) 0;
    }
  }

  .mobile-filter {
    .fast-checks-filter {
      &__check-type {
        width: 100%;
      }
      &__blockchain {
        margin-top: 16px;
        width: 100%;
      }
      &__date-picker {
        margin-top: 16px;
        width: 100%;
      }
      &__search {
        margin-top: 16px;
        width: 100%;
      }
      &__clear {
        text-align: center;
        margin-left: auto;
        cursor: pointer;
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: underline;
        color: $main;
      }
    }
  }
  .fast-checks {
    &-content {
      margin-top: 0;
      position: relative;
    }
    .ps__rail-x {
      margin-left: auto;
      margin-right: auto;
    }
    &-list {
      padding-top: 24px;
    }
    &-mobile {
      &-list-item {
        height: 56px;
        display: flex;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid $gray-4;
        background-color: $gray-1;
        &:first-child {
          border-top: 1px solid $gray-4;
        }
        &__risk-level {
          height: 100%;
          width: 12px;
          min-width: 12px;
          border-left: 1px solid $gray-4;
          &.low {
            background: repeating-linear-gradient(
                45deg,
                $gray-1,
                $gray-1 3px,
                $red-3 3px,
                $red-3 6px
            );
          }
          &.medium {
            background: $red-3;
          }
          &.high {
            color: $gray-1;
            background: $red-6;
          }
          &.severe {
            color: $gray-1;
            background: $red-7;
          }
          &.checking {
            background: repeating-linear-gradient(
                45deg,
                $gray-1,
                $gray-1 3px,
                $gray-3 3px,
                $gray-3 6px
            );
            background-size: calc(6px / sin(45deg)) 100%;
            animation: AnimationName 1s linear infinite reverse;
          }
          &.error {
            border-right: 2px solid $red-6;
          }
        }
        &__content {
          margin-right: 14px;
          padding-left: 12px;
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          height: 100%;
          border-left: 1px solid $gray-4;
        }
        &__cel {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &__main {
          width: 100%;
          font-size: 14px;
          line-height: 22px;
          @include Ellipsis;
          &.error {
            color: $error-2;
          }
        }
        &__sub {
          width: 100%;
          color: $gray-7;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          @include Ellipsis;
        }
        &__check-type {
          @include Ellipsis;
          text-align: start;
          justify-content: start;
          font-size: 12px;
          line-height: 16px;
          align-items: flex-start;
        }
        &__amount-risky-value {
          padding-left: 8px;
          align-items: flex-end;
          margin-left: auto;
        }
        &__amount-risky-value &__main, &__amount-risky-value &__sub {
          text-align: end;
        }
        &__amount-risky-value &__sub {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
        &__created-at {
          margin-top: 3px;
          color: $gray-7;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    &-show-more {
      margin-top: 32px;
      width: 100%;
      padding: 0 16px;
      &__btn {
        padding: 20px 0;
        border: 1px solid $gray-5;
        border-radius: 2px;
      }
      &__skeleton {
        border-radius: 2px !important;
      }
    }
    &-mobile-loader {
      max-height: calc(100vh - 240px);
    }
    .bn-pagination {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
