.select-language {
  display: flex;
  align-items: center;
  justify-content: center;

  &__dropdown {
    .ant-dropdown-menu-item {
      padding: 5px 12px;
    }
    &__container {
      @include Transition;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $main;
      .flag {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
      .arrow {
        margin-left: 7px;
        fill: $main;
        width: 10px;
        height: 10px;
        path {
          fill: $main;
        }
      }
      &:hover {
        color: $blue-2;
        .arrow {
          fill: $blue-2;
        }
        .flag {
          border-color: $blue-2;
        }
      }
    }
  }
}