.transfer-fast {
  &-form {
    &__row {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .field-wrapper {
        margin-top: 0;
      }
    }
    &__row &__blockchain {
      width: 222px;
    }
    &__row &__amount {
      margin-left: 16px;
      width: 222px;
    }
    &__row &__asset {
      width: 222px;
      .bn-select {
        margin-top: 0;
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-selection-search,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-top: 4px;
    }
    &__btn {
      margin-top: 24px;
      width: 100%;
      height: 40px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .transfer-fast {
    &-form__btn {
      position: absolute;
      padding: 0 24px;
      bottom: 40px;
      width: calc(100% - 48px);
      left: 50%;
      transform: translateX(-50%);
      &.ant-btn.ant-btn-loading {
        position: absolute;
      }
    }
    &-form__choose-transfer__item__address {
      margin-right: unset;
    }
  }
}
