
.drawer {
  &-header {
    position: relative;
    height: 56px;
    background: $gray-1;
  }
  &-close-icon {
    @include FlexCenter;
    position: absolute;
    top: 16px;
    left: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    :hover {
      path {
        fill: $gray-8;
      }
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &-title {
    @include FlexCenter;
    flex-direction: column;
    height: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $gray-9;
  }
  &-subTitle {
    @include FlexCenter;
    color: $gray-7;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  &-filter {
    padding: 24px;
    &__btn-wrapper {
      position: absolute;
      padding: 0 24px;
      bottom: 16px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    &__btn {
      height: 40px;
      width: 100%;
      cursor: pointer;
    }
  }
  .ant-drawer-body {
    padding: 0;
    background: $gray-2;
  }
}

