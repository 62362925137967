.alerts-detail {
  width: 100%;
  height: 100%;
  padding: 40px 0 30px 0;
  overflow: scroll;
  a {
    color: $main;
    text-decoration: underline;
  }
  &-header {
    width: 628px;
    padding: 0 0 0 16px;
    display: flex;
    justify-content: space-between;
    &__main {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }
    &__sub-title {
      font-size: 14px;
      line-height: 22px;
      .bn-dropdown__overlay {
        left: 0;
        top: 25px;
      }
      .bn-dropdown__overlay::after {
        right: auto;
        left: 10px;
      }
    }
    &__risk-level {
      width: 84px;
      height: 24px;
      border-radius: 40px;
      text-align: center;
      font-size: 12px;
      line-height: 24px;
      &.low {
        background: $gray-3;
      }
      &.medium {
        background: $red-3;
      }
      &.high {
        color: $gray-1;
        background: $red-6;
      }
      &.severe {
        color: $gray-1;
        background: $red-7;
      }
    }

  }
  &-item {
    width: 302px;
    padding: 0 16px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid $gray-3;
    height: 32px;
    &:nth-child(2n+1) {
      margin-top: 24px;
    }
    &__key {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 22px;
      text-transform: uppercase;
      color: $gray-8;
    }
    &__value {
      @include Ellipsis;
      white-space: nowrap;
      font-size: 14px;
      line-height: 22px;
      .copy-content{
        svg {
          margin-left: 8px;
        }
      }
      &.date {
        span {
          margin-left: 4px;
          color: $gray-8;
          text-transform: lowercase;
        }
      }
      b {
        font-weight: 500;
      }
      a {
        color: $main;
        text-decoration: underline;
      }
      &.high {
        color: $error-2;
      }
      &.severe {
        color:$red-7;
      }
      &.medium {
        color: $red-3;
      }
      &.low {
        color: $gray-7;
      }
      &.no_risk,
      &.none,
      &.unknown {
        color: $gray-9;
      }
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      padding: 0;
    }
    .ant-select-arrow {
      right: 0;
    }
  }
  &-group {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    &__left, &_right {
      width: 50%;
    }
    &__left {
      padding-left: 16px;
      padding-right: 24px;
    }
    &__bottom {
      padding: 0 40px 0 16px;
      margin-top: 24px;
      width: 100%;
      .alerts-detail-item {
        width: 100%;
        height: 40px;
        &:nth-child(2n+1) {
          margin-top: 0;
        }
      }
    }
    &__assignment {
      color: $main;
    }
    &__status {
      color: $main;
      &__popup {
        .ant-select-item-option-selected {
          display: none;
        }
      }
      &__icon {
        transform: rotate(90deg);
        width: 16px;
        height: 16px;
        path {
          fill: $main;
        }
      }
      &__loading {
        width: 8px;
        height: 8px;
        top: 50%;
        left: -9px;
      }
    }
    &__categories {
      &.active {
        border-bottom: 2px solid $main;
      }
      .alerts-detail-item__value {
        cursor: pointer;
        color: $main;
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: underline;
      }
    }
    &__ellipse {
      flex-wrap: nowrap;
      @include Ellipsis;
      .alerts-detail-item__value {
        margin-top: 4px;
        margin-left: 8px;
      }
      .hint-content {
        @include Ellipsis;
      }

    }
  }
  &-categories {
    padding-left: 16px;
    background-color: $gray-light;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__top {
      width: 100%;
      min-height: 34px;
    }
    &__content {
      padding-bottom: 24px;
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    &__chart {
      margin-left: 16px;
    }
    &__list {
      margin-left: 40px;
      width: 302px;
      height: fit-content;
      &__title {
        margin-left: 16px;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
    }
    &__item {
      background-color: $gray-light;
      height: 32px;
      width: 100%;
      padding: 5px 16px;
      font-size: 12px;
      line-height: 22px;
      color: $gray-8;
      display: flex;
      justify-content: space-between;
      //border-bottom: 1px solid $gray-1;
      span {
        color: $gray-9;
        font-size: 14px;
        line-height: 22px;
      }
      &:not(:first-child) {
        border-top: 1px solid $gray-3;
      }
    }
  }
  &-add-comment {
    margin-top: 16px;
    display: flex;
    align-items: flex-end;
    &__textarea {
      padding: 8px 16px;
      width: 355px;
      height: 44px;
      border-color: $gray-3;
    }
    &__submit.default-btn.transparent {
      margin-left: 24px;
      color: $main;
      border-color: $gray-3;
      border-radius: 2px;
      &:disabled {
        background: #f3f4f9;
        border: 1px solid #d7d8e0;
        color: $gray-6;
        &:hover {
          color: $gray-6;
        }
      }
      &:hover {
        color: $blue-2;
      }
      &:focus {
        color: $blue-3;
        border-color: $blue-3;
      }
    }
    .ck-editor__editable {
      min-width: 389px;
      min-height: 60px;
    }
  }
  &-activity-history-section {
    padding: 0 40px 0 16px;
    margin-top: 24px;
    &__title {
      margin-left: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  &-activity-history-list {
    margin-left: 16px;
    margin-top: 24px;
  }
  &-activity-history {
    position: relative;
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 24px;
      &:before {
        content: "";
        width: 1px;
        height: 26px;
        position: absolute;
        top: -25px;
        left: 8px;
        background-color: $gray-4;
      }
    }
    &__icon {
      width: 16px;
      height: 16px;
    }
    &__content {
      margin-left: 16px;
    }
    &__datetime {
      font-size: 12px;
      line-height: 16px;
    }
    &__title {
      font-size: 12px;
      line-height: 16px;
      span {
        color: $pink;
      }
    }
    &__comment {
      margin-top: 6px;
      color: $gray-8;
      font-size: 12px;
      font-style: italic;
      line-height: 16px;
      width: 604px;
      word-wrap: break-word;
    }
    &.skeleton {
      .ant-skeleton-element .ant-skeleton-avatar {
        width: 16px;
        height: 16px;
      }
      .alerts-detail-activity-history__content {
        display: flex;
        flex-direction: column;
      }
      .alerts-detail-activity-history__content__datetime {
        width: 100px;
        height: 16px;
      }
      .alerts-detail-activity-history__content__title {
        width: 250px;
        margin-top: 4px;
        height: 16px;
      }
    }
  }
  &-activity-history-show-all {
    margin-top: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    &:before {
      content: "";
      width: 1px;
      height: 12px;
      position: absolute;
      top: -17px;
      left: 8px;
      background-color: $gray-4;
    }
    &__icon {
      cursor: pointer;
      width: 16px;
      height: 16px;
      background-color: $gray-3;
      border-radius: 100%;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        width: 16px;
        height: 16px;
        path {
          fill: $gray-7;
        }
      }
    }
    &__text {
      cursor: pointer;
      margin-left: 16px;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: underline;
      color: $gray-7;
    }
  }
  .loading-page {
    width: 100%;
    height: 100%;
  }
}

.alerts-detail-mobile {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  .alerts-detail-item {
    width: 100%;
    margin-top: 0;
  }
}

@media screen and (max-width: $max-sm-media) {
  .alerts-detail {
    &-group {
      &__categories {
        //border-bottom: 2px solid transparent;
        &.active {
          border-color: transparent;
          position: relative;
          .alerts-detail-item__key {
            font-weight: 500;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 16px);
            height: 2px;
            background: $main;
            border-radius: 1px 1px 0 0;

          }
        }
      }
    }
    &-categories {
      position: relative;
      padding-left: 0;
      background-color: $gray-1;
      &__top {
        height: 24px;
        min-height: 24px;
      }
      &__content {
        flex-direction: column-reverse;
      }
      &__list {
        width: 100%;
        margin-left: 0;
      }
      &__chart {
        margin-top: 24px;
        &.sankey-chart, &.pie-chart {
          margin-left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &__minimize {
        position: absolute;
        bottom: 8px;
        right: 8px;
        transform: rotate(-90deg);
        path {
          fill: $main
        }
      }
    }
    &-activity-history {
      &-list {
        margin-left: 0;
        margin-top: 40px;
      }
      &-section {
        padding: 0 16px;
        margin-top: 40px;
        &__title {
          margin-left: 0;
        }
      }
      &__comment {
        max-width: 100%;
        width: 100%;
      }
    }
    &-add-comment {
      flex-direction: column;
      align-items: baseline;
      &__textarea {
        width: 100%;
        &__wrapper {
          width: 100%;
        }
      }
      &__submit {
        margin-top: 16px;
        &.default-btn.transparent {
          margin-left: 0;
        }
      }
    }
    &-item {
      padding: 0 16px;
    }
  }
}
