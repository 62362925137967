.access-management-detail {
  padding: 40px;
  border: 1px solid $gray-4;
  background: $gray-1;
  border-radius: 8px;
  position: relative;
  max-height: 596px;

  &__head {
    display: flex;
    align-items: center;
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
  &__edit {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 16px;
  }
  &-actions {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 40px;
    top: 40px;
    &__cancel {
      width: 81px;
      margin-right: 8px;
      border-radius: 2px;
    }
    &__save {
      width: 184px;
      border-radius: 2px;
      font-size: 16px;
      line-height: 24px;
    }
    & > button {
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-form {
    width: 400px;
    margin-top: 24px;
    &__permissions {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      &__title {
        font-size: 16px;
        line-height: 24px;
      }
      .ant-checkbox-wrapper {
        margin-left: 0;
        font-size: 16px;
        line-height: 24px;
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
    }
    &__email {
      position: relative;
    }
    &__resend-btn {
      position: absolute;
      left: calc(100% + 16px);
      top: 25px;
      height: 40px;
      border-radius: 2px;
      &.ant-btn.ant-btn-loading {
        position: absolute;
      }
    }
  }
  &-edit-name__btn {
    height: 40px;
    margin-top: 24px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }
  &-delete {
    position: absolute;
    top: calc(100% + 24px);
    right: 0;
    text-decoration-line: underline;
    border: none;
    &.default-btn.transparent {
      color: $red-6;
      font-size: 16px;
      line-height: 22px;
      padding: 0;
      text-decoration-line: underline;
      border: none;
      svg, path {
        fill: $red-6;
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .access-management-detail {
    max-height: unset;
    max-width: unset;
    padding: 24px;
    border: unset;
    background: unset;
    &-form {
      width: 100%;
      margin-top: 0;
      &__email {
        position: unset;
        margin-top: 24px;
        margin-bottom: 24px;
      }
      &__resend-btn {
        width: 100%;
        margin-top: 24px;
        position: unset;
      }
      &__permissions {
        margin-top: 24px;
      }
    }
    &-actions {
      margin-top: 40px;
      position: unset;
      &__cancel {
        width: 124px;
        margin-right: 15px;
      }
      &__save {
        width: 100%;
      }
      & > button {
        height: 40px;
      }
    }
  }
}