.alerts-mobile-filter {
  .alerts-filter {
    &__risk-level, &__subject {
      margin-bottom: 16px;
      width: 100%;
    }
    &__category {
      width: 100%;
    }
    &__asset {
      margin-left: 16px;
      width: 116px;
    }
    &__clear {
      text-align: center;
    }
  }
  .date-picker {
    margin-bottom: 16px;
    width: 100%;
  }
}