.alerts {
  &-header {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    &__bottom {
      margin-top: 24px;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }
  &-filter {
    margin-left: 8px;
    display: flex;
    align-items: center;
    .select:not(:last-child) {
      margin-right: 16px;
    }
    &__risk-level {
      width: 180px;
    }
    &__alert-status {
      width: 180px;
    }
    &__subject {
      width: 180px;
    }
    &__category {
      width: 180px;
    }
    &__asset {
      width: 140px;
    }
    .date-picker {
      width: 244px;
    }
    &__search {
      margin-left: 8px;
      width: 280px;
    }
    &__clear {
      margin-left: auto;
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      text-decoration-line: underline;
      color: $main;
    }
  }
  &-content {
    position: relative;
    margin-top: 43px;
    .ps__rail-x,
    .ps__thumb-x {
      display: none !important;
    }
  }
  &-title {
    margin-left: 8px;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
  }
  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-radius: 8px;
    width: 100%;
    tr:not(.first-child) {
      @include Transition;
      &:hover {
        background-color: $gray-light
      }
    }
    th {
      padding: 9px 16px 9px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;
      &:first-child {
        padding-left: 8px;
        padding-right: 8px;
        text-align: center;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }
    td {
      height: 56px;
      max-height: 56px;
      padding-left: 8px;
      border-bottom: 1px solid $gray-4;
      &:first-child {
        padding-left: 0;
        position: relative;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }
    &__item.active {
      background-color: $gray-light
    }
    &__item.clickable {
      cursor: pointer;
    }
    &__risk-level {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      .alerts-table__main {
        @include Ellipsis;
        max-width: 140px;
      }
      &:has(.alerts-table__sub) .alerts-table__main {
        margin-top: 8px;
      }
      .alerts-table__sub {
        @include Ellipsis;
        display: inline-block;
        max-width: 120px;
      }
      &__cel {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 84px;
        height: 24px;
        border-radius: 2px;
        font-size: 12px;
        line-height: 16px;
        &.low {
          background-color: $gray-1;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23FFA39E' stroke-width='4' stroke-dasharray='10%2c 11' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
      }
    }
    &__subject {
      width: 156px;
      min-width: 156px;
      max-width: 156px;
    }
    &__amount {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
    }
    &__risk-value {
      width: 110px;
      min-width: 110px;
      max-width: 110px;
    }
    &__client-id {
      margin-left: 4px;
      .copy-dropdown__text {
        @include Ellipsis;
        max-width: 144px;
      }
    }
    &__created-at {
      width: 110px;
    }
    &__event-time {
      width: 110px;
    }
    &__status {
      width: 110px;
      .alerts-table__main {
        @include Ellipsis;
        max-width: 90px;
      }
    }
    &__main {
      font-size: 14px;
      line-height: 22px;
    }
    &__sub {
      display: flex;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      color: $gray-8;
    }
    &__category {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
      &__cel {
        .alerts-table__main {
          @include Ellipsis;
          max-width: 160px;
        }
      }
    }
    &.noBorderBottom {
      border-bottom: none;
      td {
        border-bottom: none;
      }
    }
    &__empty {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-tabs {
    display: flex;
    flex-direction: row;

    top: 3px;
    right: 0;
    position: absolute;
    &-wrapper {
      top: 3px;
      right: 0;
      position: absolute;
    }
  }
  &-tab {
    cursor: pointer;
    padding: 0 8px;
    color: $gray-8;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
    @include Transition;
    &.active {
      border-bottom: 2px solid $main;
      color: $main
    }
    &:hover {
      color: $main;
    }
  }
  &-show-more {
    margin-top: 8px;

    &__btn {
      padding: 20px 0;
      width: 100%;
      background: $gray-2;
      border: 1px solid $gray-4;
      border-radius: 2px 2px 16px 16px;
      color: $main;

      &:hover {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }

      &:focus {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }
    }

    &__skeleton {
      height: 42px !important;
      width: 100% !important;

      border: 0.5px solid $gray-5;
      border-radius: 2px 2px 16px 16px !important;
    }
  }
  &-skeleton {
    width: 100%;
    &__risk-level {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-skeleton-button {
        width: 84px;
        height: 24px;
      }
    }
    .alerts-table__main, .alerts-table__sub {
      .ant-skeleton-button {
        height: 16px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .alerts {
    &-filter {
      margin-left: 0;
      &__risk-level, &__subject, &__category, &__asset {
        width: 168px;
      }
      .date-picker {
        width: 224px;
      }
      &__search {
        margin-left: 24px;
        width: 226px;
      }
    }
    &-table {
      width: 996px;
      &__content {
        width: calc(996px + (100% - 720px));
        padding-left: 80px;
      }
      &__wrapper {
        overflow: scroll;
        padding-bottom: 16px;
      }
    }
    .ps__rail-x {
      border-radius: 4px;
      background: $gray-2;
      height: 10px;
      margin-left: 80px;
      margin-right: calc(100vw - 720px - 80px);
    }
    &-tabs {
      justify-content: flex-end;
      flex-direction: row;
      &__menu {
        margin-left: 10px;
        margin-right: -19px;
        height: 24px;
        transform: rotate(90deg);
        &__trigger {
          display: inline-block;
          width: 100%;
          svg, path {
            fill: $main;
          }
        }

      }
    }
    &-tab {
      flex-shrink: 0;
    }
  }
  .bn-dropdown__menu li.selected {
    color: $main
  }
}

@media screen and (max-width: $more-md-media) {
  .alerts {
    &-table {
      width: 996px;
      &__content {
        padding-left: calc((100vw - 720px) / 2);
        padding-right: calc((100vw - 720px) / 2);
      }
    }
    .ps__rail-x {
      margin-left: calc((100vw - 720px) / 2);
      margin-right: calc((100vw - 720px) / 2);
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .alerts {
    &-content {
      margin-top: 0;
    }
    &-tabs {
      position: static;
      padding: 24px 24px 0;
      justify-content: normal;
      flex-direction: row;

      span.fast-filter-icon {
        position: absolute;
        background: $gray-light;
        cursor: pointer;
        right: 15px;
        bottom: -3px;
        svg path {
          fill: $gray-8;
        }
      }

      &__warapper {
        padding-bottom: 16px;
      }
    }
    .ps__rail-x {
      margin-left: auto;
      margin-right: auto;
    }
    &-mobile-list-item {
      height: 56px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-top: 1px solid $gray-4;
      background-color: $gray-1;
      &:last-child {
        border-bottom: 1px solid $gray-4;
      }
      &.isClientDetail {
        padding: 0 16px;
        .alerts-table__risk-level__box {
          height: 22px;
          width: 62px;
        }
      }
      &__risk-level {
        height: 100%;
        width: 12px;
        min-width: 12px;
        border-left: 1px solid $gray-4;
        &.low {
          background: repeating-linear-gradient(
              45deg,
              $gray-1,
              $gray-1 3px,
              $red-3 3px,
              $red-3 6px
          );
        }
        &.medium {
          background: $red-3;
        }
        &.high {
          color: $gray-1;
          background: $red-6;
        }
        &.severe {
          color: $gray-1;
          background: $red-7;
        }
      }
      &__content {
        margin-right: 14px;
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-left: 1px solid $gray-4;
      }
      &__cel {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__main {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        @include Ellipsis;
      }
      &__sub {
        width: 100%;
        color: $gray-8;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        @include Ellipsis;
      }
      &__created-at {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-left: 7px;
        width: 82px;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: $gray-8;
        span {
          &:first-child {
            color: $gray-9;
            line-height: 20px;
          }
        }
      }
      &.isClientDetail &__created-at {
        color: $gray-8;
      }
      &.isClientDetail &__sub {
        line-height: 16px;
        font-size: 12px;
      }
      &__type-category {
        @include Ellipsis;
        text-align: start;
        justify-content: start;
        font-size: 12px;
        line-height: 16px;
        align-items: flex-start;
        padding-left: 8px;
      }
      &.isClientDetail &__type-category {
        margin-left: 16px;
      }
      &__amount-risky-value {
        padding-left: 8px;
        align-items: flex-end;
        margin-left: auto;
      }
      &.isClientDetail &__amount-risky-value{
        @include Ellipsis;
      }
      &__amount-risky-value &__main, &__amount-risky-value &__sub {
        text-align: end;
      }
      &.isClientDetail &__amount-risky-value &__sub {
        text-align: end;
      }
      &__amount-risky-value &__sub {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    &-show-more {
      margin-top: 32px;
      width: 100%;
      padding: 0 16px;
      &__btn {
        padding: 20px 0;
        border: 1px solid $gray-5;
        border-radius: 2px;
      }
      &__skeleton {
        border-radius: 2px !important;
      }
    }
    &-mobile-loader {
      max-height: calc(100vh - 240px);
    }
    .bn-pagination {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
