@import '../../../src/common/styles/variables';
.details-empty {
  width: 100%;

  h5 {
    color: $gray-9;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 16px 6px;
  }

  &__content {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;

    height: 122px;
    background: $gray-1;
    border-top: 1px solid $gray-4;
    border-bottom: 1px solid $gray-4;

    svg {
      height: 24px;
      width: 24px;

      path {
        fill: $gray-5;
      }
    }
  }

  &__title {
    text-transform: lowercase;
    color: $gray-9;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.details-empty__title::first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: $max-sm-media) {
  .details-empty {
    h5 {
      margin: 0 0 16px 24px;
    }
  }
}
