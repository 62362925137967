.ant-select-selector {
  border: none !important;
  padding-top: 1px !important;
}

.ant-select-arrow {
  padding-top: 1px !important;

  .added-icon {
    color: $green-3;
  }
}

.ant-select-arrow svg {
  fill: $main;
}

.ant-select-item-option-active {
  &[aria-selected="false"] {
    background-color: $gray-1;
  }
}