.fast-checks-details {
  &-risks {
    &__empty {
      min-height: 192px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      div {
        height: 100%;
        display: flex;
        align-items: center;
      }
      span {
        font-weight: 500;
        margin-left: 16px;
      }
      &__icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}
