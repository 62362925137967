.multiple {
  margin-top: 10px;

  display: flex;
  align-items: center;
  gap: 12px 16px;
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;
    gap: 4px;

    border: 1px solid $gray-4;
    background: $gray-2;
    cursor: pointer;
    padding: 2px 8px;

    color: $gray-9;
    font-size: 14px;
    line-height: 24px;

    svg {
      width: 14px;
      height: 14px;

      path {
        fill: $gray-9;
      }
    }
  }
}
