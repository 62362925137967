.monitored {
  &-check-transfers {
    .default-modal__content {
      padding: 32px 32px 24px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &__check {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-info {
          display: flex;
          gap: 16px;
          align-items: center;

          svg {
            width: 22px;
            height: 22px;
          }

          &-title {
            color: $gray-9;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }
        }

        span {
          color: $gray-9;
          font-size: 16px;
          line-height: 20px;
          padding-left: 38px;
        }
      }

      &__btn {
        display: flex;
        gap: 8px;
        margin-left: auto;
      }
    }
  }

  &-historical-import {
    .select {
      height: 40px;
      .ant-select-selector {
        height: 100%;
        align-items: center;
      }
      .ant-select-selection-item {
        font-size: 16px;
      }
    }
    &-date {
      input {
        font-size: 16px;
      }
    }
    .ant-picker-range {
      height: 40px;
    }

    &__switcher {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 24px;

      .ant-switch {
        background: $gray-5;
      }

      .ant-switch-checked {
        background: $pink;
      }

      &-icon {
        display: flex;
        gap: 6px;
        align-items: center;

        span {
          color: $gray-9;
          font-size: 14px;
          line-height: 22px;
        }

        svg {
          path {
            fill: #D7D8E0;
          }
        }
      }

      &-btn {
        width: 100%;
        height: 40px;
        margin-top: 24px;
      }
    }
  }

  &__transfer-block {
    @include emptyBlock();
  }
}
