.clients-details {
  &__monitoring-transfer {
    margin-left: 0;
  }

  &-main {
    padding: 24px 24px 40px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid $gray-4;
    background: $gray-1;
    &__header {
      margin-bottom: 38px;
      width: 460px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__main {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
      }
      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }
      &__sub-title {
        font-size: 14px;
        line-height: 22px;
      }
      &__additional {
        flex-direction: row;
        display: flex;
        align-items: center;
      }
      &__menu {
        margin-left: auto;
        transform: rotate(90deg);
        &__trigger {
          display: inline-block;
          width: 100%;
          svg, path {
            fill: $pink;
          }
        }
      }
    }
    &__left, &__right {
      width: 460px;
      .alerts-detail-item:first-child {
        margin-top: 0;
      }
    }
    &__right > .alerts-detail-item:last-child,
    &__left > .alerts-detail-item:last-child {
      border-bottom: none;
    }
    .alerts-detail-item {
      width: 100%;
    }
    &__flow {
      .alerts-detail-item__value {
        display: flex;
        align-items: center;
        text-align: center;
        .asset-amount {
          font-weight: 500;
        }
        span {
          color: $gray-8;
          margin-left: 4px;
        }
      }
    }
  }
  &-exposure {
    margin-top: 40px;
    padding: 24px 58px 72px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid $gray-4;
    background: $gray-1;
    &-list {
      width: 460px;
      height: fit-content;
      &__header {
        margin-left: 16px;
        margin-bottom: 35px;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
      }
      &__empty {
        height: 108px;
        display: flex;
        align-items: center;
        justify-content: center;
        &__text {
          color: $gray-8;
          text-align: center;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &__item {
      height: 32px;
      width: 100%;
      padding: 5px 16px;
      font-size: 12px;
      line-height: 22px;
      color: $gray-8;
      display: flex;
      justify-content: space-between;
      span {
        color: $gray-9;
        font-size: 14px;
        line-height: 22px;
      }
      &:not(:first-child) {
        border-top: 1px solid $gray-3;
      }
    }
    &-switch {
      margin-top: 32px;
      .ant-radio-button-wrapper {
        color: $gray-8;
        font-size: 14px;
        height: 32px;
      }
      .radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: $gray-9;
        border-color: $gray-4;
        background-color: $gray-4;
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: $gray-4;
      }
      .radio .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
        color: $gray-9;
      }
    }
    &__chart {
      margin-top: 60px;
    }
  }
  &-alerts {
    position: relative;
    min-height: 240px;
    margin-top: 40px;
    padding-bottom: 24px;
    padding-top: 24px;
    border-radius: 8px;
    border: 1px solid $gray-4;
    background: $gray-1;
    &__head {
      position: relative;
    }
    &__title {
      margin-left: 40px;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 24px;
    }
    &__empty {
      min-height: 192px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      div {
        height: 100%;
        display: flex;
        align-items: center;
      }
      span {
        font-weight: 500;
        margin-left: 16px;
      }
      &__icon {
        width: 40px;
        height: 40px;
      }
    }
    .alerts-table {
      border: none;
      border-radius: 0;
    }
    .alerts-show-more {
      margin-top: 0;
    }
    .alerts-table__item:last-child {
      td {
        border-bottom: none;
      }
    }
    .pagination {
      margin-left: auto;
    }
    .bn-pagination__search_wrapper {
      margin-right: 24px;
    }
    .alerts-tabs {
      right: 24px;
      width: auto;
      max-width: none;
      min-width: 0;
    }
  }
  &-transfers {
    min-height: 240px;
    margin-top: 40px;
    border-radius: 8px 8px 16px 16px;
    border: 1px solid $gray-4;
    background: $gray-1;
    padding-top: 24px;
    &__title {
      margin-left: 29px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 24px;
      line-height: 28px;
    }
    &__list {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: calc(240px - 68px);
    }
    .transfer {
      border-left: 0;
      border-right: 0;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &__btn {
      margin-top: auto;
      display: inline-block;
      border-radius: 2px 2px 16px 16px;
      background: $gray-2;
      height: 48px;
      padding: 11px 16px;

      width: 100%;
      color: $main;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;

      &:hover {
        background: $gray-light;
        text-decoration: underline;
      }
    }
    &__empty {
      min-height: 192px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      div {
        height: 100%;
        display: flex;
        align-items: center;
      }
      span {
        font-weight: 500;
        margin-left: 16px;
      }
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .clients-details {
    &-main {
      &__header, &__left, &__right {
        width: 100%;
      }
      &__left {
        margin-bottom: 24px;
      }
      &__left > .alerts-detail-item:last-child {
        border-bottom: 1px solid $gray-3;
      }
    }
    &-exposure {
      padding-right: 40px;
    }
    &-alerts {
      border-radius: 0;
      border: none;
      background: transparent;
      .alerts-table {
        border: 1px solid $gray-4;;
        border-radius: 8px;
      }
      .ps__rail-x {
        border-radius: 4px;
        background: $gray-2;
        height: 10px;
        margin-left: 80px;
        margin-right: calc(100vw - 720px - 80px);
      }
    }
    .alerts-table__wrapper {
      width: auto;
      overflow: scroll;
    }
    .alerts-table {
      margin-top: 0;
      width: 996px;
    }

  }
}

@media screen and (max-width: $more-md-media) {
  .clients-details {
    &-alerts {
      .ps__rail-x {
        margin-left: calc((100vw - 720px) / 2);
        margin-right: calc((100vw - 720px) / 2);
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .clients-details {
    margin-bottom: 40px;
    &-main {
      background-color: transparent;
      padding: 8px 8px 8px;
      border: none;
      .alerts-detail-item:nth-child(2n+1) {
        margin-top: 0;
      }
    }
    &-exposure {
      margin-top: 40px;
      padding: 24px 8px 48px 8px;
      border: none;
      background-color: transparent;
      flex-direction: column;
      &-list {
        width: 100%;
        &__header {
          margin-bottom: 24px;
        }
        &__title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
        &__items {
          margin-right: 0;
        }
      }
      &-switch {
        margin-top: 16px;
      }
      &__chart {
        margin-top: 24px;
        display: flex;
        justify-content: center;
      }
    }
    &-alerts {
      min-height: unset;
      padding-top: 0;
      padding-bottom: 0;
      &__title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-left: 24px;
        margin-bottom: 16px;
      }
      & .alerts-tabs {
        margin-top: 0;
      }
      &__btn {
        height: 40px;
        padding: 8px;
        width: 100%;
        color: $main;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        justify-content: center;
        &:hover {
          color: $main;
        }
      }
    }
    &-transfers {
      min-height: unset;
      padding-top: 0;
      background-color: transparent;
      border: none;
      &__title {
        margin-left: 24px;
        margin-bottom: 32px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
      &__btn {
        height: 40px;
        padding: 8px;
        background-color: transparent;
        &:hover {
          color: $main;
        }
      }
      .transfer:last-child {
        margin-bottom: 0;
      }
    }
    &-alerts.empty, &-transfers.empty {
      margin-left: 8px;
      margin-right: 8px;
      border-radius: 8px;
      border: 1px solid $gray-4;
      background-color: $gray-1;
      height: 180px;
    }
  }
}
