.monitored-wallets {

  &__empty-block {
    display: flex;
    height: 80vh;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
      color: $gray-9;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 24px;
      line-height: 24px;
    }

    button {
      height: 40px;
      width: 282px;
    }
  }

  &-filter {
    &__asset {
      width: 140px;
    }
  }


  &-delete-modal {
    .checkbox {
      width: 100%;
      margin-bottom: 20px;
      label {
        color: $gray-9;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .confirm-modal__content {
      p {
        color: $gray-9;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
    
    .confirm-modal__footer {
      flex-direction: column;
      padding-left: 37px;

      div {
        &:last-child {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  @media screen and (max-width: $max-sm-media) {
    &-filter {
      &__asset {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }

  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-bottom: none;
    //border-radius: 8px;
    border-radius: 8px 8px 0 0;
    width: 100%;
    margin-top: 24px;

    tr:not(.first-child) {
      @include Transition;

      &:hover {
        background-color: $gray-light
      }
    }

    th {
      height: 40px;
      padding-left: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;

      &:first-child {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:not(:first-child) {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }

    td {
      height: 56px;
      max-height: 56px;
      padding-left: 8px;
      border-bottom: 1px solid $gray-4;
      @include Ellipsis;

      &:first-child {
        padding-left: 40px;
      }
    }

    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }

    &__item {
      cursor: pointer;
    }

    &__item_blocked {
      background: $red-1;
      &:hover {
        background: $red-1 !important;
      }
    }

    &__main {
      font-size: 14px;
      line-height: 22px;
      @include Ellipsis;

      &.error {
        color: $red-6;
      }
    }

    &__sub {
      display: flex;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      color: $gray-7;
      @include Ellipsis;
    }

    &__live-import {
      width: 124px;
      min-width: 124px;
      max-width: 124px;

      .ant-switch {
        background: $gray-5;
      }

      .ant-switch-checked {
        background: $pink;
      }
    }

    &__item &__live-import {
      display: flex;
      align-items: center;
      flex-direction: row;

      &__icon {
        margin-right: 12px;
      }
    }

    &__live-import &__main {
      width: 180px;
      @include Ellipsis;
    }

    &__wallet-name {
      width: 128px;
      min-width: 128px;
      max-width: 128px;
    }

    &__address {
      width: 224px;
      min-width: 224px;
      max-width: 224px;
    }

    &__assets {
      width: 152px;
      min-width: 152px;
      max-width: 152px;
    }

    &__transfers {
      width: 104px;
      min-width: 104px;
      max-width: 104px;
    }

    &__last-transfer {
      width: 130px;
      min-width: 130px;
      max-width: 130px;
    }

    &__connected-at {
      width: 134px;
      min-width: 134px;
      max-width: 134px;
    }

    &__empty {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__list-item {
    border-top: 1px solid $gray-4;
    background: $gray-1;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      padding: 6px 0;

      &_key {
        color: $gray-9;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }

      &_value {
        color: $gray-8;
        font-size: 14px;
        line-height: 22px;
      }
    }

    &-right {
      padding: 6px 0;
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: space-between;

      &_value {
        color: $gray-9;
        font-size: 14px;
        line-height: 22px;
      }

      &_live {
        color: $gray-7;
        font-size: 12px;
        line-height: 16px;
      }

      &_pink {
        color: $pink;
      }
    }
  }

  &__list-item-blocked {
    background: $red-1;
  }
}
