
.animated-visibility-enter {
  opacity: 0;
  z-index: 1;
}

.animated-visibility-enter.animated-visibility-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.animated-visibility-exit {
  opacity: 1;
  z-index: 1;
}

.animated-visibility-exit.animated-visibility-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-in;
}


.animated-accordion-enter {
  overflow: hidden;
  max-height: 0;
}

.animated-accordion-enter-active {
  max-height: 1000px;
  transition: max-height 0.6s ease-in-out;
}

.animated-accordion-exit {
  max-height: 1000px;
}

.animated-accordion-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);
}
