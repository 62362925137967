.invoices {
  &-header {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    &__bottom {
      margin-top: 24px;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &__new-btn {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  &-filter {
    display: flex;
    align-items: center;
    .select:not(:last-child) {
      margin-right: 16px;
    }
    &__status {
      width: 196px;
    }
    &__name {
      width: 180px;
    }
    &__asset {
      width: 140px;
    }
    .date-picker {
      width: 228px;
    }
    &__search {
      margin-left: 16px;
      width: 188px;
    }
    &__clear {
      margin-left: auto;
      cursor: pointer;
      font-size: 14px;
      line-height: 22px;
      text-decoration-line: underline;
      color: $main;
    }
  }
  &-form {
    &__asset-amount {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .field-wrapper {
        margin-top: 16px;
      }
    }
    &__asset, &__amount {
      width: 222px;
    }
    &-submit {
      width: 100%;
      height: 40px;
      margin-top: 16px;
    }
  }
  &-content {
    position: relative;
    margin-top: 43px;
  }
  &-title {
    margin-left: 8px;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  &-tabs {
    display: flex;
    flex-direction: row;

    top: 3px;
    right: 0;
    position: absolute;
    &-wrapper {
      top: 3px;
      right: 0;
      position: absolute;
    }
  }
  &-tab {
    cursor: pointer;
    padding: 0 8px;
    color: $gray-8;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    @include Transition;
    &.active {
      border-bottom: 2px solid $main;
      color: $main
    }
    &:hover {
      color: $main;
    }
  }

  &-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: transparent;
  }
  &-table {
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    background: $gray-1;
    border: 1px solid $gray-4;
    border-bottom: none;
    //border-radius: 8px;
    border-radius: 8px 8px 0 0;
    width: 100%;
    margin-top: 24px;
    tr:not(.first-child) {
      @include Transition;
      &:hover {
        background-color: $gray-light
      }
    }
    th {
      height: 40px;
      padding-left: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      background: $gray-2;
      &:first-child {
        padding-left: 40px;
        padding-right: 8px;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 22px;
          background-color: $gray-4;
        }
      }
    }
    td {
      height: 56px;
      max-height: 56px;
      padding-left: 8px;
      border-bottom: 1px solid $gray-4;
      @include Ellipsis;
      &:first-child {
        padding-left: 12px;
      }
    }
    tr:not(:first-child) {
      border-bottom: 1px solid $gray-4;
    }

    &__item {

    }
    &__main {
      font-size: 14px;
      line-height: 22px;
      @include Ellipsis;
      &.error {
        color: $red-6;
      }
    }
    &__sub {
      display: flex;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      color: $gray-7;
      @include Ellipsis;
    }
    &__status {
      width: 230px;
      min-width: 230px;
      max-width: 230px;
    }
    &__item &__status {
      display: flex;
      align-items: center;
      flex-direction: row;
      &__icon {
        margin-right: 12px;
      }
    }
    &__status &__main {
      width: 180px;
      @include Ellipsis;
    }
    &__amount {
      width: 130px;
      min-width: 130px;
      max-width: 130px;
    }
    &__wallet {
      width: 170px;
      min-width: 170px;
      max-width: 170px;
    }
    &__invoice-id {
      width: 140px;
      min-width: 140px;
      max-width: 140px;
    }
    &__client-id {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
    }
    &__created-at {
      width: 110px;
      min-width: 110px;
      max-width: 110px;
    }
    &__time-left {
      width: 96px;
      min-width: 96px;
      max-width: 96px;
    }
    &__time-left &__main {
      color: $gray-7;
    }
    &__empty {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-show-more {
    width: 100%;

    &__btn {
      padding: 20px 0;
      width: 100%;
      background: $gray-2;
      border: 1px solid $gray-4;
      border-top: none;
      border-radius: 0 0 16px 16px;

      color: $main;

      &:hover {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
        border-top: none;
      }

      &:focus {
        color: $main;
        background: $gray-3;
        border: 1px solid $gray-3;
      }
    }

    &__skeleton {
      height: 42px !important;
      width: 100% !important;

      border: 0.5px solid $gray-5;
      border-radius: 2px 2px 16px 16px !important;
    }
  }
}


@media screen and (max-width: $max-md-media) {
  .invoices {
    &-header {
      padding: 0;
    }
    &-content {
      margin-top: 0;
    }
    &-table {
      &__wrapper {
        overflow: scroll;
      }
    }
    &-form {
      &__asset, &__amount {
        width: 100%;
      }
    }
    &-mobile-filter {
      .drawer-filter {
        display: flex;
        flex-direction: column;
      }
    }
    &-filter {
      flex-direction: column;

      &__status, &__name, &__asset, .date-picker {
        margin-bottom: 16px;
        width: 100%;
      }
      &__search {
        margin-top: 16px !important;
        margin-left: 0;
        width: 100% !important;
      }
    }
  }
}
