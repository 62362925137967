.wallets-detail-mobile {
  padding-bottom: 12px;

  &-live {
    padding: 24px;
    background: $gray-2;

    .ant-switch {
      width: 44px;
      background: $gray-5;
    }

    .ant-switch-checked {
      width: 44px;
      background: $pink;
    }

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        color: $gray-9;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
    }

    &-check {
      display: flex;
      align-items: center;
      gap: 16px;

      &-icon {
        display: flex;
        align-items: center;
        gap: 6px;

        span {
          color: $gray-9;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }

        svg {
          path {
            fill: #D7D8E0;
          }
        }
      }
    }
  }

  &-live-blocked {
    background: $red-1;
    p.text {
      color: $red-6;
      padding: 0 0 8px;
      width: 80%;
    }
  }

  &-form {
    padding: 16px 24px;
  }

  &-historial {
    padding: 24px 16px;
    background: $gray-2;

    h4 {
      padding-left: 16px;
      color: $gray-9;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 16px;
    }

    &-items {
      display: flex;
      flex-direction: column;

      &-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $gray-3;
        padding: 5px 0;

        &-value {
          color: $gray-8;
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          text-transform: uppercase;
          padding-left: 16px;
        }

        &-key {
          color: $gray-9;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          padding-right: 16px;
        }

        &-not-checked {
          padding-right: 16px;
          color: $main;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-decoration-line: underline;
        }
      }

      &-item:last-child {
        border-bottom: none;
      }
    }
  }

  &-btn {
    display: flex;
    gap: 15px;
    width: 100%;
    padding: 8px 16px 40px;
    position: fixed;
    bottom: 0;
    background: $gray-light;

    &-cancel {
      width: 37.8%;
      height: 40px;
      padding: 8px 37.5px;
      background: #FFF !important;

      span {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    &-save {
      width: 57.6%;
      height: 40px;
      padding: 8px 45px;

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  &-transfers__title {
    margin-bottom: 16px;
  }
}
