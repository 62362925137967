.access-management {
  width: 690px;
  min-height: 396px;
  position: relative;
  &-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  &-add-member {
    position: absolute;
    top: 40px;
    right: 40px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
  &-members {
    margin-top: 40px;
    border-collapse: collapse;
    width: 100%;
    th {
      text-align: start;
      color: $gray-8;
      font-size: 14px;
      line-height: 24px;
      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 16px;
      }
    }
    td {
      height: 56px;
      max-height: 56px;
      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 16px;
      }
    }
    &__item {
      @include Transition;
      cursor: pointer;
      &:hover {
        background-color: $gray-light;
      }
      &:not(:first-child) {
        border-top: 1px solid $gray-4;
      }
    }
    &__main {
      font-size: 14px;
      line-height: 22px;
      &.inviteSent {
       color: $pink;
      }
    }
    &__sub {
      color: $gray-8;
      font-size: 12px;
      line-height: 16px;
    }
    &__team-member {
      width: 172px;
      min-width: 172px;
      max-width: 172px;
    }
    &__job-title {
      width: 188px;
      min-width: 188px;
      max-width: 188px;
    }
    &__registration-date {
      width: 135px;
      min-width: 135px;
      max-width: 135px;
    }
    &__email {
      color: $main;
      &.notRegistered {
        color: $gray-7;
      }
    }
    &__empty {
      margin: 75px 0;
    }
    &__skeleton {
      height: 56px !important;
      width: 100% !important;
    }
  }
  &-new-member {
    &__permissions {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      &__title {
        font-size: 16px;
        line-height: 24px;
      }
      .ant-checkbox-wrapper {
        margin-left: 0;
        font-size: 16px;
        line-height: 24px;
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
    }
    &__btn {
      height: 40px;
      margin-top: 24px;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: $max-md-media) {
  .access-management {
    width: 720px;
  }
}

@media screen and (max-width: $max-sm-media) {
  .access-management {
    width: 100%;
    padding: 0;
    background: unset;
    border: unset;
    min-height: unset;
    &-members {
      &-mobile {
        margin-top: 8px;
        &__item {
          width: 100%;
          height: 56px;
          padding: 8px 24px 8px 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $gray-4;
          &__full-name {
            font-size: 14px;
            line-height: 22px;
          }
          &__job {
            font-size: 12px;
            line-height: 16px;
            color: $gray-8
          }
          &__email {
            font-size: 14px;
            line-height: 22px;
            color: $main;
            &.notRegistered {
              color: $gray-7;
            }
          }
          &__invite {
            font-size: 12px;
            line-height: 16px;
            color: $pink;
          }
        }
      }
    }
    &-mobile-new-memeber {
      padding: 0 24px;
    }
  }
}