* {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
}

body {
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
  .layout-page {
    display: flex;
    flex-direction: column;
    flex: auto;
    min-height: 100dvh;
    &.onboarding {
      max-height: 100vh;
      overflow-y: hidden;
      .main-layout {
        height: 100vh;
        max-height: 100vh;
        overflow-y: hidden;
      }
    }
  }

  .main {
    flex: 1 0 auto;
    background: $gray-light;
    padding-top: 40px;
    padding-bottom: 80px;
    position: relative;

    &.p-t-0 {
      padding-top: 0;
    }
    &.p-b-0 {
      padding-bottom: 0;
    }
    &.croppedScreen {
      max-width: 100vw;
      max-height: 100vh;
      overflow: hidden;
    }
    &.alerts {
      padding-top: 24px;
    }
  }

  .iWithoutMainPadding {
    padding-top: 0 !important;
  }

}

@media screen and (max-width: $max-sm-media) {
  body {
    -webkit-text-size-adjust: none;
    .main {
      background: $gray-light;
      padding-bottom: 104px;
      padding-top: 16px;

      &.light-grey {
        background: $gray-light;
      }
      &.noMobileMenu {
        padding-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: $max-sm-media) {
  .mobile__p-t-0 {
    padding-top: 0 !important;
  }
}

.container {
  width: 100%;
  max-width: 996px;
  margin: 0 auto;
}

.sidebarExists {
  .container {
    width: 100%;
    max-width: 996px;
    margin-left: 56px;
  }
}

@media screen and (max-width: $update-container-margin) {
  .container {
    margin: 0 auto;
  }
}
@media screen and (max-width: $max-md-media) {
  .container {
    width: 720px;
    margin-left: 80px;
  }
}
@media screen and (max-width: $more-md-media) {
  .container {
    width: 720px;
    margin: auto;
  }
}
@media screen and (max-width: $max-sm-media) {
  .container {
    width: 100%;
    margin: 0 auto;
    &.mob-p-8 {
      padding: 0 8px;
    }
    &.mob-p-16 {
      padding: 0 16px;
    }
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: normal;
}

h1,
h2,
p {
  margin: 0;
}

a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
}


.loading-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .default-spinner {
    width: 48px;
    height: 48px;
  }
}

.loading-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .default-spinner {
    width: 48px;
    height: 48px;
  }
}
