.search-select-input {
  background-color: $gray-1;
  border: 1px solid $gray-4;
  height: 32px;
  width: 100%;
  padding-left: 4px;
  padding-right: 26px;
  border-radius: 2px;
  &.transparent {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $gray-5;
  }
  &:focus{
    outline: none;
  }
  &::placeholder {
    color: $gray-5
  }
  &__wrapper {
    position: relative;
  }
  &__search, &__close {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }
  &__search {
    width: 16px;
    height: 16px;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    &:hover, &:focus {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
    &__icon {
      width: 16px;
      height: 16px;
      fill: $gray-6;
      path {
        fill: $gray-6;
      }
    }

  }
  &__close {
    cursor: pointer;
    width: 12px;
    height: 12px;
    fill: $gray-5;
    path {
      fill: $gray-5;
    }
  }
  &__dropdown {
    padding: 0;
    border-radius: 0 0 2px 2px;
    max-height: 114px;
    overflow: scroll;
    box-shadow: 0px 8px 28px 8px rgba(0, 0, 0, 0.08), 0px 2px 6px -4px rgba(0, 0, 0, 0.14) !important;
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
      padding: 5px 8px 5px 24px;
    }
    &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: $gray-1 !important;
    }
    .search-select-input__dropdown__item {
      margin: 0;
    }
  }
}